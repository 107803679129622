import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  CardHeader,
  doubleSpace,
  Paragraph,
  PageHeader,
  Subheader,
  quadSpace,
  SectionHeader,
  singleSpace,
  tripleSpace,
} from "components/sharedComponents";

const StyledPrivacyPolicy = styled.section`
  border-top: 1px solid ${blue};
  padding: 30px 15px;

  @media only screen and (min-width: 768px) {
    padding: 60px 15px;
  }

  h1,
  h2 {
    margin-bottom: ${doubleSpace};
  }

  p {
    margin-bottom: ${singleSpace};
    line-height: 32px;
  }
`;

export const PrivacyPolicy = () => {
  return (
    <StyledPrivacyPolicy id="about">
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <PageHeader>Privacy Policy</PageHeader>
            <SectionHeader>Last revised on: January 8, 2024</SectionHeader>
            <SectionDivider />
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Paragraph>
              This privacy notice for Sanctum Technology, Inc. (“we,” “us,” or
              “our”), describes how and why we might collect, store, use, and/or
              share ("process") your information when you use our services
              (“Services”), such as when you:
              <ul>
                <li>
                  Visit our website at http://www.hellosanctum.com, or any
                  website of ours that links to this privacy notice
                </li>
                <li>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </li>
                Questions or concerns? Reading this privacy notice will help you
                understand your privacy rights and choices. If you do not agree
                with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us
                at privacy@hellosanctum.com.
              </ul>
            </Paragraph>
            <Subheader>SUMMARY OF KEY POINTS</Subheader>
            <Paragraph>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </Paragraph>
            <Paragraph>
              What personal information do we process? When you visit, use, or
              navigate our Services, we may process personal information
              depending on how you interact with us and the Services, the
              choices you make, and the products and features you use. Learn
              more about personal information you disclose to us.
            </Paragraph>
            <Paragraph>
              Do we process any sensitive personal information? If sensitive
              information is included in the data you provide to our service, it
              may be processed.
            </Paragraph>
            <Paragraph>
              Do we receive any information from third parties? We receive
              information from third parties that you authorize.
            </Paragraph>
            <Paragraph>
              How do we process your information? We process your information to
              provide, improve, and administer our Services, communicate with
              you, for security and fraud prevention, and to comply with law. We
              may also process your information for other purposes with your
              consent. We process your information only when we have a valid
              legal reason to do so. Learn more about how we process your
              information.
            </Paragraph>
            <Paragraph>
              In what situations and with which parties do we share personal
              information? We may share information in specific situations and
              with specific third parties. Learn more about when and with whom
              we share your personal information.
            </Paragraph>
            <Paragraph>
              How do we keep your information safe? We have organizational and
              technical processes and procedures in place to protect your
              personal information. However, no electronic transmission over the
              internet or information storage technology can be guaranteed to be
              100% secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be
              able to defeat our security and improperly collect, access, steal,
              or modify your information. Learn more about how we keep your
              information safe.
            </Paragraph>
            <Paragraph>
              What are your rights? Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information. Learn more
              about your privacy rights.
            </Paragraph>
            <Paragraph>
              How do you exercise your rights? The easiest way to exercise your
              rights is by visiting https://www.hellosanctum.com/product/, or by
              contacting us. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </Paragraph>
            <Paragraph>
              Want to learn more about what we do with any information we
              collect? Review the privacy notice in full.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>TABLE OF CONTENTS</Subheader>
            <ol>
              <li>
                <a href="">WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="">HOW DO WE PROCESS YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="">
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </li>
              <li>
                <a href="">
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </li>
              <li>
                <a href="">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li>
                <a href="">DO WE COLLECT INFORMATION FROM MINORS?</a>
              </li>
              <li>
                <a href="">WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li>
                <a href="">
                  DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a href="">DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li>
                <a href="">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </li>
              <li>
                <a href="">
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </a>
              </li>
            </ol>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>What Information Do We Collect</Subheader>
            <Paragraph>Personal information you disclose to us</Paragraph>
            <Paragraph>
              In Short: We collect personal information that you provide to us.
            </Paragraph>
            <Paragraph>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us. Personal Information Provided by You. The personal
              information that we collect depends on the context of your
              interactions with us and the Services, the choices you make, and
              the products and features you use. The personal information we
              collect may include the following:
              <ul>
                <li>Names</li>
                <li>email addresses</li>
                <li>phone numbers</li>
                <li>contact preferences</li>
              </ul>
            </Paragraph>
            <Paragraph>
              Sensitive Information. If sensitive information is included in the
              data you provide to our service, it may be processed.
            </Paragraph>
            <Paragraph>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>How Do We Process Your Information?</Subheader>
            <Paragraph>
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </Paragraph>
            <Paragraph>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
              <ul>
                <li>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts. We may process your
                  information so you can create and log in to your account, as
                  well as keep your account in working order.
                </li>
                <li>
                  To deliver and facilitate delivery of services to the user. We
                  may process your information to provide you with the requested
                  service.
                </li>
                <li>
                  To respond to user inquiries/offer support to users. We may
                  process your information to respond to your inquiries and
                  solve any potential issues you might have with the requested
                  service.
                </li>
                <li>
                  To send administrative information to you. We may process your
                  information to send you details about our products and
                  services, changes to our terms and policies, and other similar
                  information.
                </li>
                <li>
                  To request feedback. We may process your information when
                  necessary to request feedback and to contact you about your
                  use of our Services.
                </li>
                <li>
                  To post testimonials. We post testimonials on our Services
                  that may contain personal information.
                </li>
                <li>
                  To protect our Services. We may process your information as
                  part of our efforts to keep our Services safe and secure,
                  including fraud monitoring and prevention.
                </li>
                <li>
                  To evaluate and improve our Services, products, marketing, and
                  your experience. We may process your information when we
                  believe it is necessary to identify usage trends, determine
                  the effectiveness of our promotional campaigns, and to
                  evaluate and improve our Services, products, marketing, and
                  your experience.
                </li>
                <li>
                  To identify usage trends. We may process information about how
                  you use our Services to better understand how they are being
                  used so we can improve them.
                </li>
                <li>
                  To comply with our legal obligations. We may process your
                  information to comply with our legal obligations, respond to
                  legal requests, and exercise, establish, or defend our legal
                  rights.
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Subheader>
            <Paragraph>
              In Short: We may share information in specific situations
              described in this section and/or with the following third parties.
            </Paragraph>
            <Paragraph>
              We may need to share your personal information in the following
              situations: Business Transfers. We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </Subheader>
            <Paragraph>
              In Short: We may use cookies and other tracking technologies to
              collect and store your information.
            </Paragraph>
            <Paragraph>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice:
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>5. HOW LONG DO WE KEEP YOUR INFORMATION?</Subheader>
            <Paragraph>
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </Paragraph>
            <Paragraph>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </Paragraph>
            <Paragraph>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</Subheader>
            <Paragraph>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </Paragraph>
            <Paragraph>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>7. DO WE COLLECT INFORMATION FROM MINORS?</Subheader>
            <Paragraph>
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.
            </Paragraph>
            <Paragraph>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              privacy@hellosanctum.com.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>8. WHAT ARE YOUR PRIVACY RIGHTS?</Subheader>
            <Paragraph>
              In Short: You may review, change, or terminate your account at any
              time.
            </Paragraph>
            <Paragraph>
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section “HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?” below.
            </Paragraph>
            <Paragraph>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </Paragraph>
            <Paragraph>
              Opting out of marketing and promotional communications: You can
              unsubscribe from our marketing and promotional communications at
              any time by clicking on the unsubscribe link in the emails that we
              send, replying “STOP” or “UNSUBSCRIBE” to the SMS messages that we
              send, or by contacting us using the details provided in the
              section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” below. You
              will then be removed from the marketing lists. However, we may
              still communicate with you — for example, to send you service
              related messages that are necessary for the administration and use
              of your account, to respond to service requests, or for other
              non-marketing purposes.
            </Paragraph>
            <Paragraph>Account Information</Paragraph>
            <Paragraph>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can: Contact us
              using the contact information provided.
            </Paragraph>
            <Paragraph>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </Paragraph>
            <Paragraph>
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. For further
              information, please see our Cookie Notice:
              https://www.hellosanctum.com/privacy-policy.
            </Paragraph>
            <Paragraph>
              If you have questions or comments about your privacy rights, you
              may email us at privacy@hellosanctum.com.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>9. CONTROLS FOR DO-NOT-TRACK FEATURES</Subheader>
            <Paragraph>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>
              10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </Subheader>
            <Paragraph>
              In Short: If you are a resident of California, you are granted
              specific rights regarding access to your personal information.
            </Paragraph>
            <Paragraph>
              What categories of personal information do we collect?
            </Paragraph>
            <Paragraph>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </Paragraph>
            <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal information as defined in the California Customer
                  Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under state or
                  federal law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from collected personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>L. Sensitive personal Information</td>
                <td></td>
                <td>NO</td>
              </tr>
            </table>
            <Paragraph>
              We will use and retain the collected personal information as
              needed to provide the Services or for:
              <ul>
                <li>Category B - As long as the user has an account with us</li>
              </ul>
            </Paragraph>
            <Paragraph>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
              <ul>
                <li>Receiving help through our customer support channels;</li>
                <li>Participation in customer surveys or contests; and</li>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              How do we use and share your personal information?
            </Paragraph>
            <Paragraph>
              Learn about how we use your personal information in the section,
              “HOW DO WE PROCESS YOUR INFORMATION?”
            </Paragraph>
            <Paragraph>
              Will your information be shared with anyone else?
            </Paragraph>
            <Paragraph>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information to in the section, “WHEN AND WITH WHOM DO WE SHARE
              YOUR PERSONAL INFORMATION?”
            </Paragraph>
            <Paragraph>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be “selling” of your personal information.
            </Paragraph>
            <Paragraph>
              We have not disclosed, sold, or shared any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. We will not sell or share personal
              information in the future belonging to website visitors, users,
              and other consumers.
            </Paragraph>
            <Paragraph>California Residents</Paragraph>
            <Paragraph>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </Paragraph>
            <Paragraph>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems
              (e.g., backups, etc.).
            </Paragraph>
            <Paragraph>CCPA Privacy Notice</Paragraph>
            <Paragraph>
              This section applies only to California residents. Under the
              California Consumer Privacy Act (CCPA), you have the rights listed
              below.
            </Paragraph>
            <Paragraph>
              The California Code of Regulations defines “residents” as:
            </Paragraph>
            <Paragraph>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and (2) every individual
              who is domiciled in the State of California who is outside the
              State of California for a temporary or transitory purpose
            </Paragraph>
            <Paragraph>
              All other individuals are defined as “non-residents.”
            </Paragraph>
            <Paragraph>
              If this definition of “resident” applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </Paragraph>
            <Paragraph>
              Your rights with respect to your personal data
            </Paragraph>
            <Paragraph>
              Right to request deletion of the data — Request to delete
            </Paragraph>
            <Paragraph>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </Paragraph>
            <Paragraph>Right to be informed — Request to know</Paragraph>
            <Paragraph>
              Depending on the circumstances, you have a right to know:
              <ul>
                <li>whether we collect and use your personal information;</li>
                <li>the categories of personal information that we collect;</li>
                <li>
                  the purposes for which the collected personal information is
                  used;
                </li>
                <li>
                  whether we sell or share personal information to third
                  parties;
                </li>
                <li>
                  the categories of personal information that we sold, shared,
                  or disclosed for a business purpose;
                </li>
                <li>
                  the categories of third parties to whom the personal
                  information was sold, shared, or disclosed for a business
                  purpose;
                </li>
                <li>
                  the business or commercial purpose for collecting, selling, or
                  sharing personal information; and
                </li>
                <li>
                  the specific pieces of personal information we collected about
                  you.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </Paragraph>
            <Paragraph>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </Paragraph>
            <Paragraph>
              We will not discriminate against you if you exercise your privacy
              rights.
            </Paragraph>
            <Paragraph>
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </Paragraph>
            <Paragraph>
              We do not process consumer's sensitive personal information.
            </Paragraph>
            <Paragraph>Verification process</Paragraph>
            <Paragraph>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </Paragraph>
            <Paragraph>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or fraud
              prevention purposes. We will delete such additionally provided
              information as soon as we finish verifying you.
            </Paragraph>
            <Paragraph>
              Other privacy rights
              <ul>
                <li>
                  You may object to the processing of your personal information.
                </li>
                <li>
                  You may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the information.
                </li>
                <li>
                  You can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </li>
                <li>
                  You may request to opt out from future selling or sharing of
                  your personal information to third parties. Upon receiving an
                  opt-out request, we will act upon the request as soon as
                  feasibly possible, but no later than fifteen (15) days from
                  the date of the request submission.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              To exercise these rights, you can contact us by email at
              ccpa@hellosanctum.com, by visiting
              https://www.hellosanctum.com/contact, or by referring to the
              contact details at the bottom of this document. If you have a
              complaint about how we handle your data, we would like to hear
              from you.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>11. DO WE MAKE UPDATES TO THIS NOTICE?</Subheader>
            <Paragraph>
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </Paragraph>
            <Paragraph>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Subheader>
            <Paragraph>
              If you have questions or comments about this notice, you may email
              us at privacy@hellosanctum.com or contact us by post at:
            </Paragraph>
            <Paragraph>
              Sanctum Technology, Inc.
              <br />
              8605 Santa Monica Blvd., PMB 40294
              <br />
              West Hollywood, CA
              <br />
              90069-4109
              <br />
              United States
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <Subheader>
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </Subheader>
            <Paragraph>
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it. To
              request to review, update, or delete your personal information,
              please visit: https://www.hellosanctum.com/contact
            </Paragraph>
          </Col>
        </Row>
      </Container>
    </StyledPrivacyPolicy>
  );
};

const SectionDivider = styled.div`
  height: 4px;
  background-color: ${blue};
  display: flex;
  width: 165px;
  margin-bottom: ${tripleSpace};
`;
