import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "components/AuthContext";

import { Hero } from "./components/Hero";
import { Testimonials } from "./components/Testimonials";
import { Values } from "./components/Values";
import { Features } from "./components/Features";
import { GetStarted } from "components/GetStarted";

export const HomePage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/inboxes");
  }, [navigate, user]);

  return (
    <div>
      <Hero />
    </div>
  );
};
