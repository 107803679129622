import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  blue,
  PrimaryButton,
  doubleSpace,
  Paragraph,
  md,
  Subtitle,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";

export const WaitlistConfirmation = () => {
  return (
    <StyledConfirmation>
      <Container>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            <StyledMessage>
              <Subtitle>You're on the list!</Subtitle>
              <Paragraph>
                We've added you to the waitlist. We'll send you an email when a
                spot opens up.
              </Paragraph>
              <PrimaryButton text={"Back to Home"} link={"/"} />
            </StyledMessage>
          </Col>
        </Row>
      </Container>
    </StyledConfirmation>
  );
};

const StyledConfirmation = styled.div`
  background-color: ${blue};
  color: white;
  padding: ${tripleSpace} 0;
  height: 100vh;

  ${md} {
    padding: ${quadSpace} 0;
  }
`;

const StyledMessage = styled.div`
  background-color: white;
  border-radius: 10px;
  color: black;
  padding: ${doubleSpace};
  margin: 0 auto;

  ${md} {
    padding: ${tripleSpace};
    border-radius: 20px;
  }

  p {
    margin-top: ${doubleSpace};
  }

  button {
    margin-top: ${quadSpace};
  }
`;
