import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import { ShadowBox } from "components/sharedComponents";

import { AccountInfo } from "./components/AccountInfo";
import { FlaggedEmails } from "./components/FlaggedEmails";
import { RedactedLinks } from "./components/RedactedLinks";

export const Flagged = ({ account }) => {
  return (
    <StyledFlagged>
      <AccountInfo />
      <Container>
        <ShadowBox>
          <FlaggedEmails />
          <RedactedLinks />
        </ShadowBox>
      </Container>
    </StyledFlagged>
  );
};

const StyledFlagged = styled.div``;
