import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  gray,
  doubleSpace,
  md,
  PrimaryButton,
  SectionSubheader,
  singleSpace,
} from "components/sharedComponents";

export const Purpose = ({ text }) => {
  return (
    <StyledPurpose>
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <SectionSubheader>{text}</SectionSubheader>
          </Col>
        </Row>
      </Container>
    </StyledPurpose>
  );
};

const StyledPurpose = styled.section`
  margin: ${doubleSpace};
  font-style: italic;
  font-weight: 300;
  color: ${gray};
`;
