import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  ActionButton,
  ErrorMessage,
  Paragraph,
  SectionHeader,
} from "components/sharedComponents";

import { SummarySection } from "./SummarySection";
import { ExecutiveSummary } from "./ExecutiveSummary";
import { EmailBodyModal } from "./modals/EmailBodyModal";
import { ActionAppliedModal } from "./modals/ActionAppliedModal";
import {
  findMessageSection,
  scrollToActionElement,
  sortMessages,
  useArchive,
  useDenySender,
  useCreateMessageEvent,
  useFetchEmailBody,
  useGenerateSummaryEmail,
  StyledResults,
  StyledResultsHeader,
} from "./utils";

export const OrganizeResults = ({ jobId, action, messageId, results }) => {
  const [threads, setThreads] = useState(results);
  const [pageError, setPageError] = useState();
  const [emailBody, setEmailBody] = useState();
  const [archived, setArchived] = useState();
  const [loading, setLoading] = useState();
  const [deniedSenders, setDeniedSenders] = useState([]);
  const { error, archiveOrRestore } = useArchive({ jobId });
  const { denyOrAllow } = useDenySender();
  const createMessageEvent = useCreateMessageEvent();
  const { loading: loadingBody, fetchEmailBody } = useFetchEmailBody();
  const { generateSummaryEmail } = useGenerateSummaryEmail();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const inboxThreads = threads["inbox"] || [];
  const notificationThreads = threads["notifications"] || [];
  const newsletterThreads = threads["newsletters"] || [];

  const archiveThreadIds = [...newsletterThreads, ...notificationThreads].map(
    ({ id }) => id,
  );

  const openMessage = useCallback(
    async (emailId) => {
      const body = await fetchEmailBody(emailId);
      setEmailBody(body);
    },
    [fetchEmailBody, setEmailBody],
  );

  const moveMessage = useCallback(
    async (messageId, scrollTo) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const dest = source === "inbox" ? "notifications" : "inbox";

        const sourceList = threads[source];
        const destList = threads[dest];
        const email = sourceList.find((email) => email.id === messageId);
        email.moved = true;

        const eventType =
          dest === "inbox" ? "move_to_inbox" : "remove_from_inbox";

        setThreads({
          ...threads,
          [source]: sourceList.filter(({ id }) => id !== messageId),
          [dest]: [...destList, email],
        });

        await createMessageEvent({
          jobId,
          senderList: email.from,
          type: eventType,
          threadId: messageId,
          threadTimestamp: email.send_date,
        });

        scrollTo && scrollToActionElement(messageId);
      } catch (err) {
        setPageError(err.message);
      }
    },
    [createMessageEvent, jobId, threads],
  );

  const applyArchive = async () => {
    if (!archiveThreadIds.length) return;

    try {
      setLoading(true);
      await archiveOrRestore({
        archive: true,
        threadIds: archiveThreadIds,
      });
      await generateSummaryEmail(jobId);
      setArchived(true);
      navigate(`/summary/${jobId}?redirect=gmail`);
    } catch (err) {
      setPageError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const applyRestore = useCallback(async () => {
    try {
      setLoading(true);
      await archiveOrRestore({ archive: false });
      setArchived(false);
    } catch (err) {
      setPageError(err.message);
    } finally {
      setLoading(false);
    }
  }, [archiveOrRestore]);

  const denySender = useCallback(
    async (messageId) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const sourceList = threads[source];
        const email = sourceList.find((email) => email.id === messageId);

        let shouldDeny = true;
        const senderEmail = email.from[0].email;
        const denied = deniedSenders.find((sender) => sender === senderEmail);

        if (denied) {
          shouldDeny = false;
          const updatedDenyList = deniedSenders.filter(
            (sender) => sender !== senderEmail,
          );
          setDeniedSenders(updatedDenyList);
        } else {
          setDeniedSenders([...deniedSenders, senderEmail]);
        }

        await denyOrAllow({ deny: shouldDeny, senderEmail });
      } catch (err) {
        setPageError(err.message);
      }
    },
    [deniedSenders, denyOrAllow, threads],
  );

  useEffect(() => {
    if (!action) return;

    scrollToActionElement(messageId || action);

    if (action === "apply") applyArchive();
    if (action === "undo") applyRestore();
    if (action === "view") openMessage(messageId);
    if (action === "move") moveMessage(messageId, true);
    if (action === "deny") denySender(messageId);
  }, []);

  return (
    <StyledResults>
      <StyledResultsHeader>
        <Paragraph>
          Below is a proposal for how I would organize your inbox for you. If
          you like it, you can click the "Apply" button at the bottom. You can
          move anything you think is in the wrong spot before applying the
          changes.
        </Paragraph>
      </StyledResultsHeader>

      <ExecutiveSummary
        type="proposal"
        inboxThreads={inboxThreads}
        newsletterThreads={newsletterThreads}
        notificationThreads={notificationThreads}
      />

      <SectionHeader style={{ margin: "90px 0 15px" }}>
        What I would summarize for you
      </SectionHeader>
      <Paragraph style={{ marginBottom: "45px" }}>
        All the summarized emails are still available, they would just live in
        the "Sanctum" folder. You can also find them via search and in your "All
        Mail" folder.
      </Paragraph>

      <SummarySection
        key="notifications"
        section="notifications"
        title="These look like Notifications"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(notificationThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
      />
      <SummarySection
        key="newsletters"
        section="newsletters"
        title="These look like Newsletters"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(newsletterThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
      />

      <SectionHeader style={{ marginBottom: 0 }}>
        What I would leave in your Inbox
      </SectionHeader>

      <SummarySection
        key="inbox"
        section="inbox"
        denySender={denySender}
        deniedSenders={deniedSenders}
        messages={sortMessages(inboxThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
      />

      {(error || pageError) && (
        <ErrorMessage>{error || pageError}</ErrorMessage>
      )}

      <div style={{ marginBottom: "30px" }}>
        {archived ? (
          <ActionButton
            disabled={loading}
            id="apply"
            onClick={applyRestore}
            text={loading ? "Working..." : "Undo"}
          />
        ) : (
          <ActionButton
            disabled={loading || !archiveThreadIds.length}
            id="apply"
            onClick={applyArchive}
            text={
              loading
                ? "Working..."
                : archiveThreadIds.length
                  ? "Apply to Inbox"
                  : "Nothing to Archive"
            }
          />
        )}
      </div>

      {(loadingBody || emailBody) && (
        <EmailBodyModal
          loading={loadingBody}
          emailBody={emailBody}
          onClose={() => setEmailBody(null)}
        />
      )}

      <ActionAppliedModal
        action="restore"
        isOpen={redirect === "gmail"}
        onClose={() => navigate({ search: "" }, { replace: true })}
      />
    </StyledResults>
  );
};
