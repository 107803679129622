import React, { createContext, useContext } from "react";
import { AuthContext } from "components/AuthContext";
import axios from "axios";

const HttpClientContext = createContext();

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.hellosanctum.com/api/v1/"
    : "http://localhost:8000/api/v1/";

const httpClient = axios.create({
  baseURL,
  paramsSerializer: {
    indexes: null,
  },
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("sanctum-app-key:user");
      window.location.href = "/login?expired_session=true";
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  },
);

const HttpClientProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const get = async ({ url, params }) => {
    let headers = {};
    if (user) {
      headers = {
        Authorization: `Token ${user?.token}`,
      };
    }

    try {
      return await httpClient.get(url, {
        headers,
        params,
      });
    } catch (err) {
      const error = new Error(
        `${err.message}: ${JSON.stringify(err.response?.data)}`,
      );
      error.code = err.response.status;
      throw error;
    }
  };

  const post = async ({ url, payload }) => {
    let headers = {};
    if (user) {
      headers = {
        Authorization: `Token ${user?.token}`,
      };
    }

    try {
      return await httpClient.post(url, payload, { headers });
    } catch (err) {
      const error = new Error(
        `${err.message}: ${JSON.stringify(err.response?.data)}`,
      );
      error.code = err.response.status;
      throw error;
    }
  };

  return (
    <HttpClientContext.Provider value={{ get, post }}>
      {children}
    </HttpClientContext.Provider>
  );
};

export { HttpClientContext, HttpClientProvider, httpClient };
