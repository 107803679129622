import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import styled from "styled-components";

import {
  darkBlue,
  darkGray,
  quadSpace,
  Paragraph,
  blueOpaque,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";

export const AppointmentBooking = ({ headline, type }) => {
  return (
    <Container>
      <Row>
        <Col md={{ offset: 1, span: 10 }}>
          <StyledMessage>
            <Paragraph>
              Success you're on the list!
              <br />
              <br />
              For the next week, we're offering a small number of customers the
              opportunity to move up the waitlist and get early access to the
              Sanctum product by doing a 15-minute interview with our user
              experience team.
              <br />
              <br />
              To sign up, pick one of the times below.
            </Paragraph>
          </StyledMessage>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <InlineWidget url="https://calendly.com/sanctum-bc/sanctum-beta-access" />
        </Col>
      </Row>
    </Container>
  );
};

const StyledMessage = styled.h4`
  padding: ${quadSpace};
  border: 2px solid ${blueOpaque};
  border-radius: 25px;
  margin: ${quadSpace} 0;
`;
