import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  doubleSpace,
  Paragraph,
  singleSpace,
  quadSpace,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const t1 = `${process.env.REACT_APP_ASSETS_URL}/img/product-page/sarah-z.jpeg`;
const t2 = `${process.env.REACT_APP_ASSETS_URL}/img/product-page/nikolai-d.png`;
const t3 = `${process.env.REACT_APP_ASSETS_URL}/img/product-page/diana-r.jpg`;

export const Testimonials = () => {
  return (
    <StyledTestimonials>
      <Container fluid>
        <ThreePartHeader
          title="What our customers are saying"
          subtitle="The People Have Spoken"
        />
        <Testimonial
          image={t1}
          name="Sarah Z"
          quote="Oh my gosh I’ve been wanting this for years. This is crazy."
        />
        <Testimonial
          image={t2}
          name="Nikolai D"
          quote="It feels like my inbox just shaved its head after rocking a combover for years."
        />
        <Testimonial
          image={t3}
          name="Diana R"
          quote="This is a whole new way of life."
        />
      </Container>
    </StyledTestimonials>
  );
};

const Testimonial = ({ image, name, theme, quote }) => {
  return (
    <Row>
      <Col md={{ offset: 2, span: 10 }}>
        <StyledTestimonial className={theme}>
          <img src={image} alt="Customer" />
          <div className="content-container">
            <Paragraph className="large">&ldquo;{quote}&rdquo;</Paragraph>
            <Paragraph className="small">{name}</Paragraph>
          </div>
        </StyledTestimonial>
      </Col>
    </Row>
  );
};

const StyledTestimonials = styled.section`
  margin: ${quadSpace} 0;
  .divider {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledTestimonial = styled.div`
  padding: ${quadSpace} ${doubleSpace} ${singleSpace};

  img {
    object-fit: cover;
    border-radius: 50%;
    height: 160px;
    width: 160px;
  }

  img,
  .content-container {
    display: inline-block;
  }

  .content-container {
    margin-left: ${doubleSpace};
    max-width: 70%;
  }
`;
