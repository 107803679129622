import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  ActionButton,
  ErrorMessage,
  Paragraph,
  SectionHeader,
  SectionSubheader,
  doubleQuad,
  doubleSpace,
  quadSpace,
  singleSpace,
} from "components/sharedComponents";
import { CheckBox } from "components/CheckBox";

import { SummarySection } from "./SummarySection";
import { ExecutiveSummary } from "./ExecutiveSummary";
import { EmailBodyModal } from "./modals/EmailBodyModal";
import { ActionAppliedModal } from "./modals/ActionAppliedModal";
import {
  findMessageSection,
  scrollToActionElement,
  sortMessages,
  useArchive,
  useCreateMessageEvent,
  useDenySender,
  useFetchEmailBody,
  useGenerateSummaryEmail,
  useMassArchive,
  StyledResults,
  StyledResultsHeader,
} from "./utils";

export const LargeInboxResults = ({
  jobId,
  action,
  messageId,
  results,
  largeInbox,
}) => {
  const [archived, setArchived] = useState();
  const [emailBody, setEmailBody] = useState();
  const [loading, setLoading] = useState();
  const [pageError, setPageError] = useState();
  const [threads, setThreads] = useState(results);
  const [deniedSenders, setDeniedSenders] = useState([]);
  const [massArchiveApplied, setMassArchiveApplied] = useState();
  const [massArchiveChecked, setMassArchiveChecked] = useState(false);
  const { error, archiveOrRestore } = useArchive({ jobId });
  const { denyOrAllow } = useDenySender();
  const createMessageEvent = useCreateMessageEvent();
  const { loading: loadingBody, fetchEmailBody } = useFetchEmailBody();
  const { generateSummaryEmail } = useGenerateSummaryEmail();
  const { applyMassArchive } = useMassArchive();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const inboxThreads = threads["inbox"] || [];
  const notificationThreads = threads["notifications"] || [];
  const newsletterThreads = threads["newsletters"] || [];

  const archiveThreadIds = [...newsletterThreads, ...notificationThreads].map(
    ({ id }) => id,
  );

  const totalThreadsCount = inboxThreads.length + archiveThreadIds.length;

  const openMessage = useCallback(
    async (emailId) => {
      const body = await fetchEmailBody(emailId);
      setEmailBody(body);
    },
    [fetchEmailBody, setEmailBody],
  );

  const moveMessage = useCallback(
    async (messageId, scrollTo) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const dest = source === "inbox" ? "notifications" : "inbox";

        const sourceList = threads[source];
        const destList = threads[dest];
        const email = sourceList.find((email) => email.id === messageId);
        email.moved = true;

        const eventType =
          dest === "inbox" ? "move_to_inbox" : "remove_from_inbox";

        setThreads({
          ...threads,
          [source]: sourceList.filter(({ id }) => id !== messageId),
          [dest]: [...destList, email],
        });

        await createMessageEvent({
          jobId,
          senderList: email.from,
          type: eventType,
          threadId: messageId,
          threadTimestamp: email.send_date,
        });

        scrollTo && scrollToActionElement(messageId);
      } catch (err) {
        setPageError(err.message);
      }
    },
    [createMessageEvent, jobId, threads],
  );

  const applyArchive = async () => {
    if (!archiveThreadIds.length) return;

    try {
      setLoading(true);
      await archiveOrRestore({
        archive: true,
        threadIds: archiveThreadIds,
      });

      if (massArchiveChecked) {
        await applyMassArchive();
        setMassArchiveChecked(false);
        setMassArchiveApplied(true);
      }
      await generateSummaryEmail(jobId);
      setArchived(true);
      navigate(`/summary/${jobId}?redirect=gmail`);
    } catch (err) {
      setPageError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const applyRestore = useCallback(async () => {
    try {
      setLoading(true);
      await archiveOrRestore({ archive: false });
      setArchived(false);
    } catch (err) {
      setPageError(err.message);
    } finally {
      setLoading(false);
    }
  }, [archiveOrRestore]);

  const denySender = useCallback(
    async (messageId) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const sourceList = threads[source];
        const email = sourceList.find((email) => email.id === messageId);

        let shouldDeny = true;
        const senderEmail = email.from[0].email;
        const denied = deniedSenders.find((sender) => sender === senderEmail);

        if (denied) {
          shouldDeny = false;
          const updatedDenyList = deniedSenders.filter(
            (sender) => sender !== senderEmail,
          );
          setDeniedSenders(updatedDenyList);
        } else {
          setDeniedSenders([...deniedSenders, senderEmail]);
        }

        await denyOrAllow({ deny: shouldDeny, senderEmail });
      } catch (err) {
        setPageError(err.message);
      }
    },
    [deniedSenders, denyOrAllow, threads],
  );

  useEffect(() => {
    if (!action) return;

    scrollToActionElement(messageId || action);

    if (action === "apply") applyArchive();
    if (action === "undo") applyRestore();
    if (action === "view") openMessage(messageId);
    if (action === "move") moveMessage(messageId, true);
  }, []);

  return (
    <StyledResults>
      <SectionHeader style={{ marginBottom: doubleSpace }}>
        Let's Organize Your Inbox!
      </SectionHeader>

      <StyledResultsHeader>
        <Paragraph style={{ marginBottom: doubleSpace }}>
          I reviewed your most recent emails, then categorized and summarized
          them for you.
        </Paragraph>

        <Paragraph>
          Below is my proposal for what to do with your inbox today. If you like
          it, you can click the "Apply" button at the bottom. You can move
          anything you think is in the wrong spot before applying the changes.
          I'll learn from your actions to make better future predictions.
        </Paragraph>
      </StyledResultsHeader>

      <ExecutiveSummary
        inboxThreads={inboxThreads}
        newsletterThreads={newsletterThreads}
        notificationThreads={notificationThreads}
      />

      <SectionHeader style={{ marginBottom: 0 }}>
        What I would leave in your inbox
      </SectionHeader>

      <SummarySection
        key="inbox"
        section="inbox"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(inboxThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
      />

      <SectionHeader style={{ margin: "90px 0 15px" }}>
        What I would summarize for you
      </SectionHeader>
      <Paragraph style={{ marginBottom: "45px" }}>
        All the summarized emails are still available, they would just live in
        the "Sanctum" folder. You can also find them via search and in your "All
        Mail" folder.
      </Paragraph>

      <SummarySection
        key="notifications"
        section="notifications"
        title="These look like Notifications"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(notificationThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
        condensed={largeInbox}
      />
      <SummarySection
        key="newsletters"
        section="newsletters"
        title="These look like Newsletters"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(newsletterThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={archived}
        condensed={largeInbox}
      />

      {(error || pageError) && (
        <ErrorMessage>{error || pageError}</ErrorMessage>
      )}

      {!massArchiveApplied && (
        <div style={{ marginTop: doubleQuad, marginBottom: quadSpace }}>
          <SectionSubheader style={{ marginBottom: doubleSpace }}>
            Archive All Other Messages
          </SectionSubheader>
          <div
            style={{
              marginBottom: singleSpace,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <CheckBox
              value={massArchiveChecked}
              onChange={setMassArchiveChecked}
              disabled={loading}
              size={25}
              style={{ marginRight: 10 }}
            />
            <Paragraph style={{ flex: 1 }}>
              Check here to archive all other messages from your inbox. You can
              still access them via search and the "All Mail" folder.
            </Paragraph>
          </div>
        </div>
      )}

      <div style={{ marginBottom: doubleSpace }}>
        {archived ? (
          <ActionButton
            disabled={loading}
            id="apply"
            onClick={applyRestore}
            text={loading ? "Working..." : "Undo"}
          />
        ) : (
          <ActionButton
            disabled={loading || !archiveThreadIds.length}
            id="apply"
            onClick={applyArchive}
            text={
              loading
                ? "Working..."
                : archiveThreadIds.length
                  ? "Apply to Inbox"
                  : "Nothing to Archive"
            }
          />
        )}
      </div>

      {(loadingBody || emailBody) && (
        <EmailBodyModal
          loading={loadingBody}
          emailBody={emailBody}
          onClose={() => setEmailBody(null)}
        />
      )}

      <ActionAppliedModal
        action="restore"
        isOpen={redirect === "gmail"}
        onClose={() => navigate({ search: "" }, { replace: true })}
      />
    </StyledResults>
  );
};
