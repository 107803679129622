import React from "react";
import styled from "styled-components";

import {
  gray,
  GenericButton,
  doubleSpace,
  ErrorMessage,
  quadSpace,
  PageHeader,
  Paragraph,
  SectionHeader,
  SectionSubheader,
  tripleSpace,
} from "components/sharedComponents";

export const Results = ({ results }) => {
  console.log("results are ", results);
  const updateResults = ({ index, type }) => {
    const newResults = [];
    // newResults[type] = newResults[type].splice(index, 1);
    // setResults(newResults);
  };
  return (
    <div>
      <ResultSection
        title={"Untrusted"}
        results={results}
        updateResults={updateResults}
      />
    </div>
  );
};

const ResultSection = ({ title, results, updateResults }) => {
  console.log(`results in ${title}: ${results} `);
  console.log(Object.keys(results));
  if (Object.keys(results).length === 0) {
    return (
      <StyledResultSection>
        <SectionHeader>{title}</SectionHeader>
        <SectionSubheader>Nothing at the moment</SectionSubheader>
      </StyledResultSection>
    );
  } else {
    return (
      <StyledResultSection>
        <SectionHeader>{title}</SectionHeader>
        {results.map((result, index) => (
          <EmailResult
            email={result}
            key={index}
            index={index}
            updateResults={updateResults}
          />
        ))}
      </StyledResultSection>
    );
  }

  console.log("rendering results section with ", results);
  return (
    <StyledResultSection>
      <SectionHeader>{title}</SectionHeader>
      {Object.entries(results).map(([category, messages]) => (
        <div>
          <SectionSubheader>{category}</SectionSubheader>
          {messages.map((message) => (
            <EmailResult email={message} />
          ))}
        </div>
      ))}
    </StyledResultSection>
  );
};

const EmailResult = ({ email, index, type, updateResults }) => {
  return (
    <StyledEmailResult>
      <Paragraph>
        {email.subject} ({email.senders.join(", ")})
      </Paragraph>
      <UntrustedButtons
        index={index}
        type={type}
        updateResults={updateResults}
      />
      )}
    </StyledEmailResult>
  );
};

const ResponseExpectedButtons = ({ index, type, updateResults }) => {
  return (
    <StyledButtons>
      <GenericButton>Summarize</GenericButton>
      <GenericButton onClick={updateResults(index, type)}>
        Archive
      </GenericButton>
      <GenericButton>Mute Sender</GenericButton>
    </StyledButtons>
  );
};

const TrustedButtons = () => {
  return (
    <StyledButtons>
      <GenericButton>Summarize</GenericButton>
      <GenericButton>Move To Inbox</GenericButton>
    </StyledButtons>
  );
};

const UntrustedButtons = () => {
  return (
    <StyledButtons>
      <GenericButton>Summarize</GenericButton>
      <GenericButton>Move To Inbox</GenericButton>
    </StyledButtons>
  );
};

const StyledResultSection = styled.div`
  margin: ${tripleSpace} 0;

  h2 {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledEmailResult = styled.div`
  margin: ${tripleSpace} 0;
`;

const StyledSanitize = styled.div`
  background-color: white;
  box-shadow: 0 2px 3px ${gray};
  margin: ${quadSpace} 0;
  padding: ${doubleSpace};

  h2,
  p {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledButtons = styled.div`
  button {
    border: none;
    display: inline-block;
    margin-right: 15px;
  }
`;
