import React, { useContext, useState } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  blue,
  PrimaryButton,
  doubleSpace,
  ErrorMessage,
  halfSpace,
  Input,
  quadSpace,
  SectionSubheader,
  singleSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";

export const Slack = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [team, setTeam] = useState(null);
  const { post } = useContext(HttpClientContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const res = await post({
        url: "integrations/slack/",
        payload: {
          team,
          email,
        },
      });
      if (res.status > 201) {
        setError("We couldn't find a matching Slack team and email address");
      }
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledSlack>
      <Container>
        <Row>
          <Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
            {success ? (
              <Success />
            ) : (
              <SlackForm
                email={email}
                setEmail={setEmail}
                error={error}
                handleSubmit={handleSubmit}
                loading={loading}
                team={team}
                setTeam={setTeam}
                success={success}
              />
            )}
          </Col>
        </Row>
      </Container>
    </StyledSlack>
  );
};

const Success = () => {
  return (
    <StyledSuccess>
      <SectionSubheader>
        Success! Slack has been added to your Sanctum account.
      </SectionSubheader>
    </StyledSuccess>
  );
};

const SlackForm = ({
  email,
  setEmail,
  error,
  handleSubmit,
  loading,
  team,
  setTeam,
  success,
}) => {
  return (
    <StyledSlackForm onSubmit={handleSubmit}>
      <div style={{ marginBottom: singleSpace }}>
        <SectionSubheader className="large">
          Add your Slack to Sanctum
        </SectionSubheader>
      </div>
      <Input
        autoFocus
        onChange={(e) => setTeam(e.target.value)}
        placeholder="Your Slack team ID"
        type="text"
        value={team}
      />
      <Input
        onChange={(e) => setEmail(e.target.value)}
        placeholder="The email associated with your Slack account"
        type="email"
        value={email}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledButton>
        <PrimaryButton
          text={loading ? "Adding Slack..." : "Let's Go"}
          disabled={!email || !team || loading}
          onClick={handleSubmit}
          role="submit"
        />
      </StyledButton>
    </StyledSlackForm>
  );
};

const StyledSlack = styled.div`
  flex: 1;
  background-color: ${blue};
  padding: ${quadSpace} 0;
`;

const StyledSlackForm = styled.form`
  background-color: white;
  border-radius: ${halfSpace};
  padding: ${doubleSpace};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSuccess = styled.div`
  background-color: white;
  border-radius: ${halfSpace};
  padding: ${doubleSpace};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.div`
  width: 100%;

  button {
    width: 100%;
  }
`;
