import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

import {
  gray,
  PrimaryButton,
  doubleSpace,
  ErrorMessage,
  PageHeader,
  quadSpace,
  Paragraph,
  SectionHeader,
  tripleSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { AuthContext } from "components/AuthContext";

export const AddProtectedAccount = () => {
  return (
    <GoogleOAuthProvider clientId="7290377858-annqt8vfl0sjg6ufepo42t187rtdvi56.apps.googleusercontent.com">
      <AddAccount />
    </GoogleOAuthProvider>
  );
};

const scopeList = [
  "https://www.googleapis.com/auth/gmail.insert",
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/gmail.labels",
  "https://www.googleapis.com/auth/gmail.readonly",
  "https://www.googleapis.com/auth/gmail.settings.basic",
];

const AddAccount = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [OAuthCode, setOAuthCode] = useState(null);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const { get, post } = useContext(HttpClientContext);
  const { login, user } = useContext(AuthContext);

  const authorizeGoogle = useGoogleLogin({
    accessType: "offline",
    flow: "auth-code",
    onError: (error) => setError(error.message),
    onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
    prompt: "consent",
    responseType: "code",
    scope: scopeList.join(" "),
  });

  useEffect(() => {
    const oauthCallback = async () => {
      if (user && user.email && OAuthCode) {
        try {
          setLoading(true);
          await post({
            url: "users/auth/google/callback",
            payload: {
              code: OAuthCode,
              user_id: user.id,
            },
          });
          setSuccess(true);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };
    oauthCallback();
  }, [OAuthCode, post, user]);

  const handleSuccess = async (tokenResponse) => {
    setOAuthCode(tokenResponse["code"]);
    try {
      setLoading(true);
      const res = await get({
        url: `users/invitation/${params?.id}/`,
      });
      const user = {
        token: res.data.token,
        ...res.data.user,
      };
      login(user);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <StyledSignup>
            <StyledHeader>
              <PageHeader style={{ marginBottom: "30px" }}>
                Welcome to Sanctum!
              </PageHeader>
              <Paragraph>
                You've been invited by someone who cares about you to create an
                account on Sanctum and protect yourself from scams.
              </Paragraph>
            </StyledHeader>
            {success ? (
              <Success />
            ) : (
              <Accept
                authorizeGoogle={authorizeGoogle}
                error={error}
                loading={loading}
              />
            )}
          </StyledSignup>
        </Col>
      </Row>
    </Container>
  );
};

const Success = () => {
  return (
    <div>
      <SectionHeader>Success!</SectionHeader>
      <Paragraph>
        Your account is now being protected by Sanctum. You can continue to use
        your email as normal. Suspicious links will now be removed
        automatically, and you'll see some emails are labeled with warnings.
      </Paragraph>
      <Paragraph>You can close this window.</Paragraph>
    </div>
  );
};

const Accept = ({ authorizeGoogle, error, loading }) => {
  return (
    <div>
      <Paragraph>Click the button below to get started</Paragraph>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledButton>
        <PrimaryButton
          disabled={loading}
          onClick={authorizeGoogle}
          text={
            loading ? "Setting up your account, stand by..." : "Accept Invite"
          }
        />
      </StyledButton>
    </div>
  );
};

const StyledHeader = styled.div`
  margin-bottom: 30px;
`;

const StyledSignup = styled.div`
  background-color: white;
  box-shadow: 0 2px 3px ${gray};
  margin: ${quadSpace} 0;
  padding: ${doubleSpace};

  h2,
  p {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledButton = styled.div`
  margin-top: ${tripleSpace};
  margin-bottom: ${tripleSpace};
  width: 100%;

  button {
    width: 100%;
  }
`;
