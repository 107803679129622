import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  CardHeader,
  doubleSpace,
  Paragraph,
  quadSpace,
  SecondaryButton,
  md,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const bjorn = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/bjorn.jpg`;
const mark = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/mark.jpg`;
const mike = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/mike.jpg`;
const sean = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/sean.jpg`;
const linkedin = `${process.env.REACT_APP_ASSETS_URL}/img/linkedin.png`;

export const Values = () => {
  return (
    <StyledValues>
      <Container>
        <Row>
          <Col>
            <ThreePartHeader
              title="Secure, stable technology. Built by experts."
              subtitle="Expect Excellence"
            />
            <Paragraph className="large">
              Our team combines decades of experience in technology, security,
              and artificial intelligence. Our top priority is keeping your
              family and your data secure.
            </Paragraph>
            <SecondaryButton text="About Us" link="/about" />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 2 }}></Col>
          <TeamCard
            link="https://www.linkedin.com/in/sbyrnes/"
            name="Sean Byrnes"
            src={sean}
            title="Customer Development"
          />
          <TeamCard
            link="https://www.linkedin.com/in/bjorncooley/"
            name="Bjorn Cooley"
            src={bjorn}
            title="CEO"
          />
        </Row>
        <Row>
          <Col md={{ span: 2 }}></Col>
          <TeamCard
            link="https://www.linkedin.com/in/markjacobstein/"
            name="Mark Jacobstein"
            src={mark}
            title="Business Development"
          />
          <TeamCard
            link="https://www.linkedin.com/in/mikefkim/"
            name="Mike Kim"
            src={mike}
            title="Engineering & AI"
          />
        </Row>
      </Container>
    </StyledValues>
  );
};

const TeamCard = ({ name, title, src, link }) => {
  return (
    <Col md={{ span: 4 }}>
      <StyledTeamCard>
        <img className="circle" src={src} />
        <CardHeader>{name}</CardHeader>
        <Paragraph>
          {title}
          <a href={link} target="_blank">
            <img className="linkedin" src={linkedin} />
          </a>
        </Paragraph>
      </StyledTeamCard>
    </Col>
  );
};

const StyledTeamCard = styled.div`
  img.circle {
    object-fit: cover;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: ${quadSpace};
  }

  img.linkedin {
    max-width: 20px;
    margin-left: 10px;
  }
`;

const StyledValues = styled.section`
  margin-bottom: 150px;
  text-align: center;
  h2,
  p {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
  }
  button {
    margin: ${doubleSpace} auto 0;
  }
`;
