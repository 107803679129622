import React from "react";

import styled from "styled-components";

import {
  doubleSpace,
  quadSpace,
  md,
  singleSpace,
} from "components/sharedComponents";
import { EmailCapture } from "components/EmailCapture";
import { Demo } from "./components/Demo";
import { Hero } from "./components/Hero";
import { Purpose } from "./components/Purpose";
import { Testimonials } from "./components/Testimonials";

const content = {
  hero: {
    headerText: "Make life simple again",
    subheaderText:
      "No more worrying about missing urgent messages. Sanctum manages critical communication for you.",
  },
};

export const ProductPage4 = () => {
  let demo;
  try {
    demo = `${process.env.REACT_APP_ASSETS_URL}/img/product-page/escalation-multiplayer.mp4`;
  } catch {
    demo = "";
  }
  return (
    <StyledProductPage>
      <Hero
        headerText={content.hero.headerText}
        subheaderText={content.hero.subheaderText}
      />
      <Demo src={demo} />
      <Testimonials />
      <EmailCapture headline={"Join the Waitlist"} category="inbox-demo-0.1" />
    </StyledProductPage>
  );
};

const StyledProductPage = styled.div`
  padding: ${quadSpace} 0;
`;
