import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  BasicLink,
  Input,
  Paragraph,
  ActionButton,
  SecondaryButton,
  SectionHeader,
  SectionSubheader,
  ShadowBox,
  Subheader,
  logoBlue,
  gray,
  singleSpace,
  doubleSpace,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";
import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";

export const UserSettings = () => {
  const [textOptIn, setTextOptIn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { get } = useContext(HttpClientContext);
  const { user } = useContext(AuthContext);

  return (
    <StyledDashboard>
      <Container>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <SectionHeader>User Settings</SectionHeader>
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <Subheader style={{ marginBottom: "30px" }}>
              Your Integrations
            </Subheader>
            <Integration vendor={"Gmail"} />
            <Integration vendor={"iMessage"} />
            <Integration vendor={"Slack"} />
          </Col>
        </Row>
        <StyledTextOptIn>
          <Row>
            <Col lg={{ offset: 2, span: 7 }}>
              <Subheader style={{ marginBottom: "30px" }}>
                Text Message Opt-In
              </Subheader>
              <Paragraph>
                If you want to receive urgent notifications via text, you can
                opt-in below.
              </Paragraph>
              <Input
                type="phone"
                onChange={() => setPhoneNumber(!phoneNumber)}
                placeholder={"Your best phone number"}
              />
              <label className="text-opt-in">
                <Input
                  type="checkbox"
                  onChange={() => setTextOptIn(!textOptIn)}
                />
                <Paragraph className="small">
                  I agree to receive text messages from Sanctum at the phone
                  number provided above. I understand data rates may apply, and
                  I can reply STOP to any message to opt out.
                </Paragraph>
              </label>
              <Paragraph className="small">
                <a
                  href="https://www.hellosanctum.com/terms-and-conditions"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{" "}
                |{" "}
                <a
                  href="https://www.hellosanctum.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Paragraph>
            </Col>
          </Row>
        </StyledTextOptIn>
        <Row>
          <Col lg={{ offset: 2, span: 4 }}>
            <ActionButton text="Save" />
          </Col>
        </Row>
      </Container>
    </StyledDashboard>
  );
};

const Integration = ({ vendor }) => {
  return (
    <StyledIntegration>
      <Paragraph className={"large bold"}>{vendor}</Paragraph>
      <BasicLink className={"small"}>Disconnect</BasicLink>
    </StyledIntegration>
  );
};

const StyledDashboard = styled.div`
  padding: ${quadSpace} 0;

  .row {
    margin-bottom: ${tripleSpace};
  }
`;

const StyledIntegration = styled.div`
  p {
    display: inline-block;
    min-width: 200px;
    margin-bottom: ${singleSpace};
  }
`;

const StyledTextOptIn = styled.div`
  p {
    margin-bottom: ${doubleSpace};
  }

  input {
    max-width: 400px;
    display: block;
  }

  .text-opt-in {
    input {
      height: 19px;
    }
    input,
    p {
      display: inline;
    }

    a {
      color: ${logoBlue};
    }
  }
`;
