import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";

import { SummaryResults } from "./components/SummaryResults";
import { OrganizeResults } from "./components/OrganizeResults";
import { LargeInboxResults } from "./components/LargeInboxResults";

export const JobSummary = ({ type }) => {
  const { user } = useContext(AuthContext);
  const { get } = useContext(HttpClientContext);

  const { authorizedInboxes } = user;
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [searchParams] = useSearchParams();
  const [results, setResults] = useState(null);
  const [largeInbox, setLargeInbox] = useState();

  if (!authorizedInboxes.includes("google")) {
    navigate("/inboxes");
  }

  const messageId = searchParams.get("message_id");
  const action = searchParams.get("action");

  useEffect(() => {
    const getJobResults = async () => {
      try {
        const res = await get({
          url: "jobs/status/",
          params: { id: jobId },
        });
        const status = res.data?.job?.status;
        if (
          status === "processed" ||
          status === "applied" ||
          status === "summary_sent"
        ) {
          setResults(JSON.parse(res.data.job.results));
        }
      } catch (err) {
        console.error(err);
      }
    };

    const calcLargeInbox = async () => {
      try {
        const res = await get({
          url: "/jobs/inbox-type/",
        });
        setLargeInbox(res.data?.inbox_type === "large_inbox");
      } catch (err) {
        console.error(err);
      }
    };

    getJobResults();
    calcLargeInbox();
  }, [get, jobId]);

  const renderResults = () => {
    if (!results) return;
    <div>Loading...</div>;

    let Results;

    if (type === "summary") {
      Results = SummaryResults;
    } else {
      Results = largeInbox ? LargeInboxResults : OrganizeResults;
    }

    return (
      <Results
        jobId={jobId}
        action={action}
        messageId={messageId}
        results={results}
      />
    );
  };

  return (
    <Container style={{ flex: 1 }}>
      <Row>
        <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          {renderResults()}
        </Col>
      </Row>
    </Container>
  );
};
