import React from "react";
import styled from "styled-components";

import {
  paragraphTextSize,
  quadSpace,
  singleSpace,
  Bold,
  SectionHeader,
} from "components/sharedComponents";

export const ExecutiveSummary = ({
  type,
  inboxThreads,
  notificationThreads,
  newsletterThreads,
}) => {
  return (
    <StyledExecutiveSummary>
      <SectionHeader>Executive Summary</SectionHeader>
      <ul>
        <ListItem>
          I reviewed{" "}
          <Bold>
            {inboxThreads.length +
              notificationThreads.length +
              newsletterThreads.length}
          </Bold>{" "}
          total emails
        </ListItem>
        <ListItem>
          {type === "summary" ? "I left" : "I would leave"}{" "}
          <Bold>{inboxThreads.length}</Bold> in your inbox
        </ListItem>
        <ListItem>
          I summarized and categorized the remaining{" "}
          <Bold>{notificationThreads.length + newsletterThreads.length}:</Bold>{" "}
          <ul>
            <ThreadCount
              threads={notificationThreads}
              description="notifications"
            />
            <ThreadCount
              threads={newsletterThreads}
              description="newsletters"
            />
          </ul>
        </ListItem>
      </ul>
    </StyledExecutiveSummary>
  );
};

const ThreadCount = ({ threads, description }) => {
  if (threads.length > 0) {
    return (
      <ListItem>
        <Bold>{threads.length}</Bold> {description}
      </ListItem>
    );
  }
};

const ListItem = styled.li`
  font-size: ${paragraphTextSize};
`;

const StyledExecutiveSummary = styled.div`
  margin-bottom: ${quadSpace};
  padding-bottom: ${singleSpace};
  padding-top: ${singleSpace};

  bold {
    font-weight: 600;
  }

  h2 {
    margin-bottom: ${singleSpace};
  }
`;
