import React, { useEffect, useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";
import { AuthContext } from "components/AuthContext";
import {
  blue,
  green,
  lightGray,
  Paragraph,
  SectionHeader,
  SecondaryButton,
  quadSpace,
  singleSpace,
} from "components/sharedComponents";

export const FlaggedEmails = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { get } = useContext(HttpClientContext);
  const { user } = useContext(AuthContext);
  const userId = searchParams.get("userId");
  const [flaggedEmails, setFlaggedEmails] = useState([]);

  useEffect(() => {
    const getFlaggedEmails = async () => {
      try {
        setLoading(true);
        const result = await get({
          url: "parsing/email/",
          params: {
            user_id: userId, //TODO: multiple accounts
          },
        });
        setFlaggedEmails(result.data[0]["messages"]);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    getFlaggedEmails();
  }, [get, user, userId]);

  return (
    <StyledFlaggedEmailList>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <SectionHeader style={{ marginBottom: "30px" }}>
            Redacted Emails
          </SectionHeader>
        </Col>
      </Row>
      <Error error={error} />
      {flaggedEmails !== undefined && flaggedEmails.length > 0 ? (
        flaggedEmails.map((e) => (
          <FlaggedEmail email={e} key={e.id} user={user} />
        ))
      ) : (
        <Row>
          <Col lg={{ offset: 1, span: 10 }}>
            <div>{loading ? "Loading..." : "No flagged emails yet"}</div>
          </Col>
        </Row>
      )}
    </StyledFlaggedEmailList>
  );
};

const Error = ({ error }) => {
  return error ? (
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>{error}</Col>
    </Row>
  ) : (
    <div />
  );
};

const FlaggedEmail = ({ email, user }) => {
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { post } = useContext(HttpClientContext);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await post({
        url: `parsing/email/${email.id}/`,
        payload: {
          state_change: "approve",
        },
      });
      if (res.status === 201) {
        setApproved(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>
        <StyledFlaggedEmail>
          <div className="d-flex justify-content-between align-items-center">
            <Paragraph>{email.subject}</Paragraph>
            <div className="d-flex">
              <ApproveButton
                approved={approved}
                loading={loading}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </StyledFlaggedEmail>
      </Col>
    </Row>
  );
};

const ReviewButton = ({ emailId }) => {
  return (
    <StyledReviewButton>
      <SecondaryButton
        link="/email-review"
        text="Review"
        type="white-outline"
        state={{ id: emailId }}
      />
    </StyledReviewButton>
  );
};

const ApproveButton = ({ approved, loading, handleSubmit }) => {
  let text;
  if (approved) {
    text = "Approved!";
  } else if (loading) {
    text = "Loading...";
  } else {
    text = "Approve";
  }
  return (
    <StyledApproveButton>
      <SecondaryButton
        disabled={approved || loading}
        text={text}
        type="white-outline"
        onClick={handleSubmit}
      />
    </StyledApproveButton>
  );
};

const StyledFlaggedEmailList = styled.div`
  padding: ${quadSpace} 0;
`;

const StyledFlaggedEmail = styled.div`
  padding: ${singleSpace} 0;
  border-top: 1px solid ${lightGray};
  button {
    height: 40px;
    padding: 0;
    width: 145px;
  }
  p {
    margin-bottom: 0;
  }
`;

const StyledReviewButton = styled.div`
  button {
    background-color: ${blue};
    border: none;
    color: white;
    margin-right: ${singleSpace};
  }
`;

const StyledApproveButton = styled.div`
  button {
    background-color: ${green};
    border: none;

    &:disabled {
      background-color: rgb(58, 148, 93, 0.5);
      img {
        display: none;
      }
    }
  }
`;
