import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  quadSpace,
  CardHeader,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

export const Demo = ({ src }) => {
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center">
          <StyledTitle>
            <CardHeader>Watch Sanctum in Action</CardHeader>
          </StyledTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledVideo>
            <video controls>
              <source src={src} type="video/mp4" />
            </video>
          </StyledVideo>
        </Col>
      </Row>
    </Container>
  );
};

const StyledTitle = styled.div`
  margin-bottom: ${doubleSpace};
`;

const StyledVideo = styled.div`
  margin-bottom: ${quadSpace};
  font-weight: 500;
  video {
    max-width: 100%;
    min-width: 100%;
    max-height: 70vh;
  }
`;
