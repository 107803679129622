import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  green,
  Paragraph,
  red,
  Subheader,
  SecondaryButton,
  SectionSubheader,
  quadSpace,
  doubleSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { AuthContext } from "components/AuthContext";

export const ApproveEmail = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [domains, setDomains] = useState([]);
  const params = useParams();
  const { get, post } = useContext(HttpClientContext);
  const { login } = useContext(AuthContext);

  const handleApprove = async () => {
    if (approving) {
      return;
    }
    setApproving(true);
    try {
      await post({
        url: `parsing/email/${params?.id}/`,
        payload: {
          state_change: "approve",
        },
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setApproving(false);
    }
  };

  useEffect(() => {
    const getEmailDetail = async () => {
      if (domains.length || loading) {
        return;
      }
      try {
        setLoading(true);
        const result = await get({
          url: `parsing/email/${params?.id}/`,
          params: {
            flagged: true,
          },
        });
        setDomains(result.data["domains"]);
        setEmail(result.data["email"]);
        const user = {
          token: result.data.token,
          ...result.data.user,
        };
        login(user);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    getEmailDetail();
  }, []);

  return (
    <StyledDomainList>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <StyledHeader>
            <Subheader style={{ marginBottom: "30px" }}>
              Review the links redacted from your email below
            </Subheader>
            <SectionSubheader>Subject: {email?.subject}</SectionSubheader>
            <SectionSubheader>From: {email?.sender}</SectionSubheader>
          </StyledHeader>
        </Col>
      </Row>
      <Error error={error} />
      {domains !== undefined && domains.length > 0 ? (
        domains.map((d, i) => (
          <Domain domain={d} handleApprove={handleApprove} key={i} />
        ))
      ) : (
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <div>
              {loading
                ? "Loading..."
                : "We didn't find any redacted links for this email"}
            </div>
          </Col>
        </Row>
      )}
    </StyledDomainList>
  );
};

const Error = ({ error }) => {
  return error ? (
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>{error}</Col>
    </Row>
  ) : (
    <div />
  );
};

const Domain = ({ domain, handleApprove }) => {
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { post } = useContext(HttpClientContext);

  const handleSubmit = (domain) => async () => {
    setLoading(true);
    try {
      const res = await post({
        url: "parsing/whitelist/",
        payload: {
          domain,
        },
      });
      if (res.status === 201) {
        setApproved(true);
      }
      handleApprove();
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col md={{ offset: 2, span: 8 }}>
        <StyledDomain>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <Paragraph className="domain">{domain}</Paragraph>
              <ApproveButton
                approved={approved}
                handleSubmit={handleSubmit(domain)}
                loading={loading}
              />
            </div>
          </div>
          <StyledApproveError>{error}</StyledApproveError>
        </StyledDomain>
      </Col>
    </Row>
  );
};

const ApproveButton = ({ approved, handleSubmit, loading }) => {
  let text;
  if (approved) {
    text = "Approved!";
  } else if (loading) {
    text = "Loading...";
  } else {
    text = "Approve";
  }

  return (
    <SecondaryButton
      disabled={loading || approved}
      onClick={handleSubmit}
      text={text}
      type="white-outline"
    />
  );
};

const StyledHeader = styled.div`
  margin-bottom: ${quadSpace};
`;

const StyledDomainList = styled.div`
  padding: ${quadSpace} 0;
`;

const StyledDomain = styled.div`
  padding: ${doubleSpace} 0;
  button,
  p.domain {
    height: 40px;
    padding: 0;
    width: 145px;
  }
  p {
    margin-bottom: 0;
    margin-right: ${quadSpace};
  }
  button {
    background-color: ${green};
    border: none;

    &:disabled {
      background-color: rgb(58, 148, 93, 0.5);
      img {
        display: none;
      }
    }
  }
`;

const StyledApproveError = styled.div`
  color: ${red};
`;
