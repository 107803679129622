import React, { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";

const MetaContext = createContext();

const MetaProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { get } = useContext(HttpClientContext);

  const [JobStatus, setJobStatus] = useState();
  const [JobType, setJobType] = useState();

  useEffect(() => {
    if (!user) return;

    const fetchMetaData = async () => {
      try {
        const res = await get({
          url: "jobs/meta/",
        });

        const { job_status, job_type } = res.data;
        setJobStatus(job_status);
        setJobType(job_type);
      } catch (err) {
        console.error(err);
      }
    };

    fetchMetaData();
  }, [get, user]);

  return (
    <MetaContext.Provider value={{ JobStatus, JobType }}>
      {children}
    </MetaContext.Provider>
  );
};

export { MetaContext, MetaProvider };
