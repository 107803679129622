import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  doubleSpace,
  blue,
  lightGray,
  quadSpace,
  Paragraph,
  SecondaryButton,
  CardHeader,
  tripleSpace,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const featureBackground1 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/feature-background-1.png`;
const featureBackground2 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/feature-background-2.png`;
const featureBackground3 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/feature-background-3.png`;

const features = [
  {
    content:
      "We provide testing, training, and AI-backed tools to combat online threats and keep families safe.",
    cta: "Get started",
    image: featureBackground1,
    link: "/goku",
    title: "Keep your family safe",
  },
  {
    content:
      "Sanctum works in the background, providing ongoing protection and training.",
    cta: "Get started",
    image: featureBackground2,
    link: "/goku",
    title: "Get peace of mind",
  },
  {
    content:
      "Protect  family members, friends, or coworkers, all with a single subscription.",
    cta: "Get started",
    image: featureBackground3,
    link: "/goku",
    title: "Unlimited accounts",
  },
  {
    content:
      "From small family plans to enterprise solutions, pick the option that works for you. Change at any time.",
    cta: "Get started",
    image: null,
    link: "/goku",
    title: "Affordable, flexible plans to meet everyone's needs",
  },
];

export const Features = () => {
  return (
    <StyledFeatures id="problem-statement">
      <Container>
        <Row>
          <Col xs={{ span: 12 }}>
            <ThreePartHeader
              title="Online protection that actually works"
              subtitle="At last"
            />
          </Col>
        </Row>
        <Row>
          {features.map((f, i) => (
            <Feature
              content={f.content}
              cta={f.cta}
              key={i}
              link={f.link}
              title={f.title}
              image={f.image}
            />
          ))}
        </Row>
      </Container>
    </StyledFeatures>
  );
};

const Feature = ({ content, cta, image, link, title }) => {
  console.log("link is ", link);
  return (
    <Col xs={{ span: 12 }} md={{ span: 6 }}>
      <StyledFeature className={image ? "" : "no-image"}>
        <CardHeader>{title}</CardHeader>
        <Paragraph>{content}</Paragraph>
        <SecondaryButton link={link} text={cta} />
        {image ? (
          <img alt={content} src={image} className="feature-image" />
        ) : null}
      </StyledFeature>
    </Col>
  );
};

const StyledFeatures = styled.section`
  padding: ${doubleSpace} 0 ${quadSpace};
  margin-bottom: ${quadSpace};

  .divider {
    margin-bottom: 90px;
  }
`;

const StyledFeature = styled.div`
  border-radius: 30px;
  border: 2px solid ${lightGray};
  margin-bottom: 60px;
  text-align: center;
  overflow: hidden;
  padding-top: ${tripleSpace};

  &.no-image {
    padding: 153px 0;
  }

  h5,
  p {
    margin-bottom: ${doubleSpace};
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  button {
    margin: ${doubleSpace} auto;
  }

  .feature-image {
    height: 302px; /* allows absolute calculations for cards without image */
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;
