import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { AuthContext } from "components/AuthContext";
import {
  green,
  lightGray,
  Paragraph,
  red,
  SectionHeader,
  SecondaryButton,
  quadSpace,
  singleSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";

export const RedactedLinks = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { get } = useContext(HttpClientContext);
  const { user } = useContext(AuthContext);
  const userId = searchParams.get("userId");
  const [redactedLinks, setRedactedLinks] = useState([]);

  useEffect(() => {
    const getRedactedLinks = async () => {
      try {
        setLoading(true);
        const linksResult = await get({
          url: "parsing/link/",
          params: {
            user_id: userId, //TODO: multiple accounts
            filters: JSON.stringify({
              hide_whitelist: true,
            }),
          },
        });
        setRedactedLinks(linksResult.data[0]["links"]);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    getRedactedLinks();
  }, [get, user, userId]);

  return (
    <StyledRedactedLinkList>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <SectionHeader style={{ marginBottom: "30px" }}>
            Links Removed by Sanctum
          </SectionHeader>
        </Col>
      </Row>
      <Error error={error} />
      {redactedLinks !== undefined && redactedLinks.length > 0 ? (
        redactedLinks.map((l, i) => (
          <RedactedLink link={l} key={i} user={user} />
        ))
      ) : (
        <Row>
          <Col lg={{ offset: 1, span: 10 }}>
            <div>{loading ? "Loading..." : "No redacted links yet"}</div>
          </Col>
        </Row>
      )}
    </StyledRedactedLinkList>
  );
};

const Error = ({ error }) => {
  return error ? (
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>{error}</Col>
    </Row>
  ) : (
    <div />
  );
};

const RedactedLink = ({ link, user }) => {
  const [approved, setApproved] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [loading, setLoading] = useState(false);
  const { post } = useContext(HttpClientContext);

  const handleSubmit = (domain) => async () => {
    setLoading(true);
    try {
      const res = await post({
        url: "parsing/whitelist/",
        payload: {
          domain,
        },
      });
      console.log(res);
      if (res.status === 201) {
        setApproved(true);
      }
      setLoading(false);
    } catch (err) {
      setLinkError(err.message);
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col lg={{ offset: 1, span: 10 }}>
        <StyledRedactedLink>
          <div className="d-flex justify-content-between align-items-center">
            <Paragraph>{link.domain}</Paragraph>
            <div className="d-flex">
              <Paragraph className="count">{link.count} found</Paragraph>
              <ApproveButton
                approved={approved}
                handleSubmit={handleSubmit(link.domain)}
                loading={loading}
              />
            </div>
          </div>
          <StyledLinkError>{linkError}</StyledLinkError>
        </StyledRedactedLink>
      </Col>
    </Row>
  );
};

const ApproveButton = ({ approved, handleSubmit, loading }) => {
  let text;
  if (approved) {
    text = "Approved!";
  } else if (loading) {
    text = "Loading...";
  } else {
    text = "Approve";
  }

  return (
    <SecondaryButton
      disabled={loading || approved}
      onClick={handleSubmit}
      text={text}
      type="white-outline"
    />
  );
};

const StyledRedactedLinkList = styled.div`
  padding: ${quadSpace} 0;
`;

const StyledRedactedLink = styled.div`
  padding: ${singleSpace} 0;
  border-top: 1px solid ${lightGray};
  button,
  p.count {
    height: 40px;
    padding: 0;
    width: 145px;
  }
  p {
    margin-bottom: 0;
  }
  button {
    background-color: ${green};
    border: none;

    &:disabled {
      background-color: rgb(58, 148, 93, 0.5);
      img {
        display: none;
      }
    }
  }
`;

const StyledLinkError = styled.div`
  color: ${red};
`;
