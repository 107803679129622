import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  darkBlue,
  darkGray,
  quadSpace,
  green,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";

export const InboxSurvey = ({ headline, type }) => {
  return (
    <Container>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <StyledMessage>
            Success! You're on the waitlist. We'll be in touch as soon as a spot
            opens up.
            <br />
            <br />
            We'd love your input. If you have 90 seconds, there's a 5-question
            survey below.
          </StyledMessage>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeMUshhsUpSOVQHVpV8l0-TICT-sPW0ISWIugnxjGP0mefVvg/viewform?embedded=true"
            width="640"
            height="3200"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
};

const StyledMessage = styled.h4`
  padding: ${quadSpace};
  background-color: ${green};
  color: white;
  border-radius: 25px;
  margin: ${quadSpace} 0;
`;
