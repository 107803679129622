import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  ActionButton,
  ErrorMessage,
  SectionHeader,
  doubleSpace,
} from "components/sharedComponents";

import { SummarySection } from "./SummarySection";
import { ExecutiveSummary } from "./ExecutiveSummary";
import { EmailBodyModal } from "./modals/EmailBodyModal";
import { ActionAppliedModal } from "./modals/ActionAppliedModal";
import {
  findMessageSection,
  scrollToActionElement,
  sortMessages,
  useCreateMessageEvent,
  useArchive,
  useDenySender,
  StyledResults,
  useFetchEmailBody,
} from "./utils";

export const SummaryResults = ({ jobId, action, messageId, results }) => {
  const [archived, setArchived] = useState(true);
  const [emailBody, setEmailBody] = useState();
  const [pageError, setPageError] = useState();
  const [threads, setThreads] = useState(results);
  const [deniedSenders, setDeniedSenders] = useState([]);
  const { loading, error, archiveOrRestore } = useArchive({
    jobId,
  });
  const { denyOrAllow } = useDenySender();
  const createMessageEvent = useCreateMessageEvent();
  const { loading: loadingBody, fetchEmailBody } = useFetchEmailBody();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect");

  const inboxThreads = threads["inbox"] || [];
  const notificationThreads = threads["notifications"] || [];
  const newsletterThreads = threads["newsletters"] || [];

  const openMessage = useCallback(
    async (emailId) => {
      const body = await fetchEmailBody(emailId);
      setEmailBody(body);
    },
    [fetchEmailBody, setEmailBody],
  );

  const moveMessage = useCallback(
    async (messageId) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const dest = source === "inbox" ? "notifications" : "inbox";
        const destLabel = dest === "inbox" ? "inbox" : "archive";

        const sourceList = threads[source];
        const email = sourceList.find((email) => email.id === messageId);

        email.movedLabel = `Moving to ${destLabel}...`;
        setThreads(threads);

        const eventType =
          dest === "inbox" ? "move_to_inbox" : "remove_from_inbox";
        await createMessageEvent({
          jobId,
          senderList: email.from,
          type: eventType,
          threadId: messageId,
          threadTimestamp: email.send_date,
        });

        if (dest === "inbox") {
          // restore message
          await archiveOrRestore({
            archive: false,
            threadId: messageId,
          });
        } else {
          // archive message
          await archiveOrRestore({
            archive: true,
            threadIds: [messageId],
          });
        }

        email.movedLabel = `Moved to ${destLabel}`;
        setThreads(threads);
      } catch (err) {
        setPageError(err.message);
      }
    },
    [archiveOrRestore, createMessageEvent, jobId, threads],
  );

  const applyRestore = useCallback(async () => {
    try {
      await archiveOrRestore({ archive: false });
      setArchived(false);
      navigate(`/organize-inbox/${jobId}?redirect=gmail`);
    } catch (err) {
      setPageError(err.message);
    }
  }, [archiveOrRestore, jobId, navigate]);

  const denySender = useCallback(
    async (messageId) => {
      try {
        const source = findMessageSection({ messageId, threads });
        if (!source) {
          setPageError(`Email not found in ${source}.`);
          return;
        }

        const sourceList = threads[source];
        const email = sourceList.find((email) => email.id === messageId);

        let shouldDeny = true;
        const senderEmail = email.from[0].email;
        const denied = deniedSenders.find((sender) => sender === senderEmail);

        if (denied) {
          shouldDeny = false;
          const updatedDenyList = deniedSenders.filter(
            (sender) => sender !== senderEmail,
          );
          setDeniedSenders(updatedDenyList);
        } else {
          setDeniedSenders([...deniedSenders, senderEmail]);
        }

        await denyOrAllow({ deny: shouldDeny, senderEmail });
      } catch (err) {
        setPageError(err.message);
      }
    },
    [deniedSenders, denyOrAllow, threads],
  );

  useEffect(() => {
    if (!action) return;

    scrollToActionElement(messageId || action);

    if (action === "undo") applyRestore();
    if (action === "view") openMessage(messageId);
    if (action === "move") moveMessage(messageId);
    if (action === "deny") denySender(messageId);
    scrollToActionElement(messageId);
  }, []);

  return (
    <StyledResults>
      <ExecutiveSummary
        type="summary"
        inboxThreads={inboxThreads}
        newsletterThreads={newsletterThreads}
        notificationThreads={notificationThreads}
      />

      <SectionHeader style={{ marginBottom: doubleSpace }}>
        What I archived for you
      </SectionHeader>

      <SummarySection
        key="notifications"
        section="notifications"
        title="These look like Notifications"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(notificationThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={!archived}
      />
      <SummarySection
        key="newsletters"
        section="newsletters"
        title="These look like Newsletters"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(newsletterThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={!archived}
      />

      <SectionHeader style={{ marginBottom: 0 }}>
        What I left in your Inbox
      </SectionHeader>

      <SummarySection
        key="inbox"
        section="inbox"
        deniedSenders={deniedSenders}
        denySender={denySender}
        messages={sortMessages(inboxThreads)}
        openMessage={openMessage}
        moveMessage={moveMessage}
        readOnly={!archived}
      />

      {error || pageError ? (
        <ErrorMessage>{error || pageError}</ErrorMessage>
      ) : null}

      <div style={{ marginBottom: "30px" }}>
        <ActionButton
          disabled={loading || !archived}
          id="undo"
          onClick={applyRestore}
          text={loading ? "Working..." : archived ? "Undo" : "Inbox Restored"}
        />
      </div>

      {(loadingBody || emailBody) && (
        <EmailBodyModal
          loading={loadingBody}
          emailBody={emailBody}
          onClose={() => setEmailBody(null)}
        />
      )}

      <ActionAppliedModal
        action="apply"
        isOpen={redirect === "gmail"}
        onClose={() => navigate({ search: "" }, { replace: true })}
      />
    </StyledResults>
  );
};
