import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

import {
  lightGray,
  PrimaryButton,
  ErrorMessage,
  Paragraph,
  Subheader,
  singleSpace,
  doubleSpace,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { AuthContext } from "components/AuthContext";
import { ManageGoogleInbox } from "./components/ManageGoogleInbox";

const gmailIcon = `${process.env.REACT_APP_ASSETS_URL}/img/icons/gmail.ico`;

export const Inboxes = () => {
  const { user } = useContext(AuthContext);

  const { authorizedInboxes } = user;

  if (authorizedInboxes && authorizedInboxes.includes("google")) {
    return <ManageGoogleInbox />;
  }

  return (
    <GoogleOAuthProvider clientId="7290377858-annqt8vfl0sjg6ufepo42t187rtdvi56.apps.googleusercontent.com">
      <AuthorizeGoogleInbox />
    </GoogleOAuthProvider>
  );
};

const scopeList = [
  "https://www.googleapis.com/auth/gmail.insert",
  "https://www.googleapis.com/auth/gmail.labels",
  "https://www.googleapis.com/auth/gmail.modify",
  "https://www.googleapis.com/auth/contacts.readonly",
  "https://www.googleapis.com/auth/contacts.other.readonly",
];

const AuthorizeGoogleInbox = () => {
  const navigate = useNavigate();
  const { get, post } = useContext(HttpClientContext);
  const { login, user } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [setupHeader, setSetupHeader] = useState("");
  const [setupText, setSetupText] = useState("");

  const authorizeGoogle = useGoogleLogin({
    accessType: "offline",
    flow: "auth-code",
    onError: (error) => setError(error.message),
    onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
    prompt: "consent",
    responseType: "code",
    scope: scopeList.join(" "),
  });

  const handleSuccess = async (tokenResponse) => {
    try {
      setLoading(true);
      await post({
        url: "users/auth/google/callback",
        payload: {
          code: tokenResponse["code"],
          user_id: user.id,
        },
      });
      user.authorizedInboxes.push("google");
      login(user);
      setAuthorized(true);
      setSetupHeader("Creating your inbox agent...");
      setSetupText(`We're customizing an intelligent inbox agent that understands which
          messages matter to you.`);
      const res = await post({
        url: "users/setup/",
      });
      const jobId = res.data.job_id;
      setSetupHeader("Your agent is ready. Analyzing inbox...");
      setSetupText(
        `Your agent is creating a proposal for how to clean your inbox.`,
      );
      pollForStatus({ jobId });
    } catch (err) {
      setError(err.message);
    }
  };

  const pollForStatus = async ({ jobId }) => {
    const intervalId = setInterval(async () => {
      let res;
      try {
        res = await get({
          url: "users/status/",
          params: { id: jobId },
        });

        console.log(res);
        if (res.data?.job?.status === "processed") {
          clearInterval(intervalId);
          createOrganizeJob();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  const createOrganizeJob = async () => {
    try {
      setLoading(true);
      const res = await post({
        url: `jobs/create/`,
        payload: { type: "organize" },
      });
      pollForJob({ id: res.data.job_id });
    } catch (err) {
      setError(err.message);
    }
  };

  const pollForJob = async ({ id }) => {
    const intervalId = setInterval(async () => {
      let res;
      try {
        res = await get({
          url: "jobs/status/",
          params: { id },
        });
        const status = res.data?.job?.status;
        console.log(res.data?.job);
        if (status === "processed" || status === "summary_sent") {
          clearInterval(intervalId);
          setLoading(false);
          navigate(`/organize-inbox/${id}`);
        }
      } catch (err) {
        clearInterval(intervalId);
        setError(err.message);
        console.error(err);
      }
    }, 5000);
  };

  return (
    <StyledInboxes>
      <Container>
        <Row>
          <Col md={{ offset: 3, span: 6 }}>
            {authorized ? (
              <AccountSetup
                get={get}
                navigate={navigate}
                setupHeader={setupHeader}
                setupText={setupText}
              />
            ) : (
              <Authorize
                authorizeGoogle={authorizeGoogle}
                error={error}
                loading={loading}
              />
            )}
          </Col>
        </Row>
      </Container>
    </StyledInboxes>
  );
};

const AccountSetup = ({ get, navigate, setupHeader, setupText }) => {
  return (
    <div>
      <StyledHeader>
        <Subheader>{setupHeader}</Subheader>
        <Paragraph>{setupText}</Paragraph>
        <StyledButton>
          <PrimaryButton disabled={true} text={"Working..."} />
        </StyledButton>
      </StyledHeader>
    </div>
  );
};

const Authorize = ({ authorizeGoogle, error, loading }) => {
  return (
    <div>
      <StyledHeader>
        <Subheader>Supercharge your Gmail.</Subheader>
        <Paragraph>
          Click the button below to authorize Sanctum. You'll be asked to
          provide permissions to your Gmail inbox and Contacts. We don't keep
          your email content, and we don't sell your data. We use only what we
          need to make your Gmail experience more productive.
        </Paragraph>
      </StyledHeader>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledButton>
        <button disabled={loading} onClick={authorizeGoogle}>
          <img src={gmailIcon} />
          {loading ? "Authorizing..." : "Connect Gmail"}
        </button>
      </StyledButton>
    </div>
  );
};

const StyledInboxes = styled.div`
  flex: 1;
  padding: ${quadSpace} 0;
`;

const StyledHeader = styled.div`
  padding-top: ${quadSpace};

  h3,
  p {
    margin-bottom: ${doubleSpace};
  }
  margin-bottom: ${doubleSpace};
`;

const StyledButton = styled.div`
  margin-top: ${tripleSpace};
  margin-bottom: ${tripleSpace};
  button {
    width: 280px;
    background-color: white;
    border: 1px solid ${lightGray};
    margin: 15px 0;
    border-radius: 25px;
    padding: ${singleSpace} 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
      max-width: 30px;
    }

    &:disabled {
      background-color: ${lightGray};
    }
  }
`;
