import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import {
  doubleSpace,
  PageHeader,
  quadSpace,
  Paragraph,
} from "components/sharedComponents";

export const AccountInfo = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const name = searchParams.get("name");

  return (
    <StyledWelcome>
      <Container>
        <Row>
          <Col lg={{ offset: 1, span: 10 }}>
            <StyledHeader>
              <PageHeader>{name}</PageHeader>
              <Paragraph>{email}</Paragraph>
            </StyledHeader>
          </Col>
        </Row>
      </Container>
    </StyledWelcome>
  );
};

const StyledHeader = styled.div`
  margin-bottom: 30px;
`;

const StyledWelcome = styled.div`
  padding: ${quadSpace} 0 ${doubleSpace} 0;
`;
