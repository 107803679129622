import React from "react";
import styled from "styled-components";

import {
  gray,
  PrimaryButton,
  doubleSpace,
  ErrorMessage,
  quadSpace,
  Paragraph,
  SectionHeader,
  tripleSpace,
} from "components/sharedComponents";

export const Clean = ({ error, loading, onClick }) => {
  return (
    <StyledSanitize>
      <StyledHeader>
        <SectionHeader style={{ marginBottom: "30px" }}>
          Your Gmail inbox is connected!
        </SectionHeader>
      </StyledHeader>
      <Submit onClick={onClick} error={error} loading={loading} />
    </StyledSanitize>
  );
};

const Submit = ({ onClick, error, loading }) => {
  return (
    <div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <StyledButton>
        <PrimaryButton
          disabled={loading}
          onClick={onClick}
          text={loading ? "Working..." : "Clean Inbox"}
        />
      </StyledButton>
    </div>
  );
};

const StyledHeader = styled.div`
  margin-bottom: 30px;
`;

const StyledSanitize = styled.div`
  margin: ${quadSpace} 0;
  padding: ${doubleSpace};

  h2,
  p {
    margin-bottom: ${doubleSpace};
  }
`;

const StyledButton = styled.div`
  margin-top: ${tripleSpace};
  margin-bottom: ${tripleSpace};
  width: 100%;

  button {
    width: 100%;
  }
`;
