import { useState, useContext, useCallback } from "react";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";

import {
  singleSpace,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";

export const sections = ["inbox", "newsletters", "notifications"];

export const sortMessages = (messages) => {
  if (!messages) return [];

  return messages.sort((a, b) => {
    const aDate = new Date(a.send_date);
    const bDate = new Date(b.send_date);
    return bDate - aDate;
  });
};

export const useCreateMessageEvent = () => {
  const { post } = useContext(HttpClientContext);

  return ({ jobId, senderList, type, threadId, threadTimestamp }) =>
    post({
      url: "/jobs/events/",
      payload: {
        job_id: jobId,
        sender_list: senderList,
        thread_id: threadId,
        thread_timestamp: threadTimestamp,
        type,
      },
    });
};

export const useArchive = ({ jobId }) => {
  const { post } = useContext(HttpClientContext);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const archiveOrRestore = useCallback(
    async ({ archive, threadId, threadIds }) => {
      const url = "/jobs/threads/" + (archive ? "archive/" : "restore/");
      const payload = {
        job_id: jobId,
      };

      if (!archive && threadId) {
        payload.thread_id = threadId; // restore one thread else all
      }
      if (archive && threadIds) {
        payload.thread_ids = threadIds; // archive multiple threads
      }

      try {
        setLoading(true);
        await post({ url, payload });
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [jobId, post],
  );

  return { loading, error, archiveOrRestore };
};

export const useDenySender = () => {
  const { post } = useContext(HttpClientContext);
  const [denySenderLoading, setLoading] = useState();
  const [denySenderError, setError] = useState();

  const denyOrAllow = useCallback(
    async ({ deny, senderEmail }) => {
      const url = "/users/" + (deny ? "deny/" : "allow/");
      const payload = {
        sender_email: senderEmail,
      };

      try {
        setLoading(true);
        await post({ url, payload });
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [post],
  );

  return { denySenderLoading, denySenderError, denyOrAllow };
};

export const useGenerateSummaryEmail = () => {
  const { post } = useContext(HttpClientContext);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const generateSummaryEmail = useCallback(
    async (jobId) => {
      const url = "/jobs/summary-email/";
      const payload = {
        job_id: jobId,
      };

      try {
        setLoading(true);
        await post({ url, payload });
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [post],
  );

  return { loading, error, generateSummaryEmail };
};

export const useMassArchive = () => {
  const { post } = useContext(HttpClientContext);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const applyMassArchive = useCallback(async () => {
    try {
      setLoading(true);
      await post({ url: "jobs/mass-archive/" });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [post]);

  return { loading, error, applyMassArchive };
};

export const useFetchEmailBody = () => {
  const { get } = useContext(HttpClientContext);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const fetchEmailBody = useCallback(
    async (threadId) => {
      try {
        setLoading(true);
        const res = await get({
          url: "/jobs/threads/",
          params: {
            thread_id: threadId,
          },
        });
        return res.data.body;
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [get],
  );

  return { loading, error, fetchEmailBody };
};

export const findMessageSection = ({ messageId, threads }) => {
  for (let section in threads) {
    const sectionThreads = threads[section];
    if (sectionThreads.find(({ id }) => id === messageId)) return section;
  }
};

export const scrollToActionElement = (elementId) => {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    targetElement.scrollIntoView({ block: "start", behavior: "instant" });
  }
};

export const StyledResults = styled.div`
  margin: ${tripleSpace} 0;
`;

export const StyledResultsHeader = styled.div`
  h1 {
    margin-bottom: ${singleSpace};
  }
  margin-bottom: ${quadSpace};
`;
