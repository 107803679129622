import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";

import {
  SectionSubheader,
  PrimaryButton,
  darkGray,
  halfSpace,
  singleSpace,
} from "components/sharedComponents";

export const ActionAppliedModal = ({ action, isOpen, onClose }) => {
  const header =
    action === "apply"
      ? "I've cleaned up your inbox!"
      : "I've restored your inbox!";

  return (
    <ReactModal
      style={htmlModalStyle}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <SectionSubheader style={{ marginBottom: 20 }}>{header}</SectionSubheader>
      <PrimaryButton
        onClick={() => {
          if (navigator.userAgent.match(/Mobi/)) {
            window.open("googlegmail://");
          } else {
            const url = "https://mail.google.com/mail/u/0/#inbox?refresh";
            if (window.top === window.self) {
              window.open(url);
            } else {
              window.parent.postMessage(
                { action: "updateParentUrl", url },
                "*",
              );
              onClose();
            }
          }
        }}
        text={window.top === window.self ? "Go to Gmail" : "Refresh Inbox"}
      />
      <StyledLink onClick={onClose}>Close</StyledLink>
    </ReactModal>
  );
};

const htmlModalStyle = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 100,
  },
  content: {
    margin: "auto",
    width: "fit-content",
    maxWidth: "calc(100% - 80px)",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "10px",
    outline: "none",
    padding: "40px",
    border: 0,
  },
};

const StyledLink = styled.a`
  color: ${darkGray};
  padding: ${halfSpace} ${singleSpace};
  cursor: pointer;
`;
