import React from "react";

import { blue } from "components/sharedComponents";

export const CheckBox = ({
  size = 20,
  value,
  onChange,
  disabled,
  style,
  ...rest
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: "5px",
        border: `1px solid ${blue}`,
        cursor: "pointer",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "all",
        ...style,
      }}
      onClick={() => onChange(!value)}
      aria-checked={value}
      role="checkbox"
      {...rest}
    >
      {value && <CheckMark size={size} />}
    </div>
  );
};

const CheckMark = ({ size = 20 }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: size * 0.4,
        height: size * 0.75,
        border: `4px solid ${blue}`,
        borderWidth: "0 2px 2px 0",
        transform: "rotate(45deg)",
        marginLeft: `${size / 4}px`,
        backgroundColor: "transparent",
      }}
    />
  );
};
