import React, { useContext, useEffect, useState, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { HttpClientContext } from "components/HttpClient";
import { MetaContext } from "components/MetaContext";

import {
  darkBlue,
  singleSpace,
  doubleSpace,
  tripleSpace,
  quadSpace,
  ErrorMessage,
  Paragraph,
  PrimaryButton,
  Subheader,
} from "components/sharedComponents";

export const ManageGoogleInbox = () => {
  const { get } = useContext(HttpClientContext);
  const { JobStatus, JobType } = useContext(MetaContext);

  const [error, setError] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRecentJobs = useCallback(async () => {
    if (!JobStatus) return;

    try {
      const res = await get({
        url: "/jobs/",
        params: {
          status: [
            JobStatus.PROCESSED,
            JobStatus.APPLIED,
            JobStatus.SUMMARY_SENT,
          ],
          type: [JobType.ORGANIZE, JobType.CLEAN],
        },
      });
      setJobs(res.data?.jobs);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [JobStatus, JobType, get]);

  useEffect(() => {
    getRecentJobs();
  }, [getRecentJobs]);

  return (
    <StyledManage>
      <Container>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ offset: 3, span: 6 }}>
            <Subheader>Your Gmail is connected</Subheader>
            <Paragraph>
              Your account is ready to go! Click the button to get a sparkling
              clean inbox.
            </Paragraph>
            <PrimaryButton
              disabled={loading}
              text="Organize my inbox"
              link="/organize-inbox"
            />
          </Col>
        </Row>
        {jobs.length > 0 && <RecentJobContainer error={error} jobs={jobs} />}
      </Container>
    </StyledManage>
  );
};

const RecentJobContainer = ({ error, jobs }) => {
  return (
    <Row>
      <Col md={{ offset: 3, span: 6 }}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Subheader>Recent Scans:</Subheader>
        <Paragraph style={{ marginBottom: "15px" }}>
          If you want to see the results of previous scans, click the links
          below:
        </Paragraph>
        {jobs.length > 0 &&
          jobs.map((job) => <RecentJob key={job.id} job={job} />)}
      </Col>
    </Row>
  );
};

const RecentJob = ({ job }) => {
  const { JobStatus } = useContext(MetaContext);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let hour = date.getHours();
    const minute = String(date.getMinutes()).padStart(2, "0"); // Zero-padded

    const isAM = hour < 12;
    if (!isAM) {
      hour = hour % 12;
      if (hour === 0) hour = 12;
    } else {
      if (hour === 0) hour = 12;
    }
    const amPm = isAM ? "AM" : "PM";

    return `${monthName} ${day}, ${year}, ${hour}:${minute} ${amPm}`;
  };

  const page =
    job.status === JobStatus.PROCESSED ? "/organize-inbox" : "/summary";

  return (
    <StyledJob>
      <Paragraph>
        <Link to={`${page}/${job.id}`}>
          {formatDate(job.end_time)} -
          {job.status === JobStatus.PROCESSED ? " Proposal" : " Summary"}
        </Link>
      </Paragraph>
    </StyledJob>
  );
};

const StyledManage = styled.div`
  flex: 1;
  padding: ${tripleSpace};
  border-radius: 25px;
  margin: ${quadSpace} 0;

  button {
    margin-top: ${doubleSpace};
  }
`;

const StyledJob = styled.div`
  margin-bottom: ${singleSpace};

  a {
    color: ${darkBlue};
  }
`;
