import React from "react";
import styled from "styled-components";

import {
  quadSpace,
  singleSpace,
  Subheader,
  SectionSubheader,
} from "components/sharedComponents";

import { EmailResult } from "./EmailResult";
import { EmailSubjectResult } from "./EmailSubjectResult";

export const SummarySection = ({
  section,
  title,
  deniedSenders,
  denySender,
  messages,
  openMessage,
  moveMessage,
  readOnly,
  condensed,
}) => {
  if (!messages || messages.length === 0) {
    return (
      <StyledResultSection>
        {title && <Subheader>{title}</Subheader>}
        <SectionSubheader>Nothing at the moment</SectionSubheader>
      </StyledResultSection>
    );
  }

  const Result = condensed ? EmailSubjectResult : EmailResult;

  return (
    <StyledResultSection>
      <Subheader>{title}</Subheader>
      {messages.map((message) => (
        <EmailResult
          section={section}
          email={message}
          deniedSenders={deniedSenders}
          denySender={denySender}
          openMessage={openMessage}
          moveMessage={moveMessage}
          readOnly={readOnly}
          key={message.id}
        />
      ))}
    </StyledResultSection>
  );
};

const StyledResultSection = styled.div`
  margin-bottom: ${quadSpace};
  padding-top: ${singleSpace};

  h2 {
    margin-bottom: ${quadSpace};
    border-bottom: 1px solid lightGray;
  }
`;
