import React, { useEffect } from "react";
import styled from "styled-components";
import { singleSpace } from "components/sharedComponents";

export const TransparentHeader = {
  width: "100%",
};

const StyledBase = styled.div`
  padding-top: ${singleSpace};
`;

export const AlignedComponent = ({ component: Component, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledBase>
      <Component {...props} />
    </StyledBase>
  );
};
