import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  ActionButton,
  ErrorMessage,
  Paragraph,
  SectionSubheader,
  lightBlue,
  lightGray,
  md,
  singleSpace,
  doubleSpace,
  tripleSpace,
  quadSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { ExecutedActions } from "./components/ExecutedActions";
import { SelectedActions } from "./components/SelectedActions";

const plan = {
  headline: "Proposed Next Steps",
  detail:
    "Based on how you’ve responded to similar issues in the past, I’ve put together the following next steps. You can select multiple to execute next.",
};

export const LaunchPad = () => {
  const [actions, setActions] = useState([]);
  const [error, setError] = useState(null);
  const [executedActions, setExecutedActions] = useState([]);
  const [executing, setExecuting] = useState(false);
  const [selectedActions, setSelectedActions] = useState([]);
  const [workflow, setWorkflow] = useState(null);
  const { get, post } = useContext(HttpClientContext);
  const { workflowId } = useParams();

  const setSortedActions = (actions) => {
    actions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    setActions(actions);
  };

  useEffect(() => {
    const getWorkflow = async () => {
      try {
        const { data } = await get({
          url: `workflows/${workflowId}`,
        });
        setWorkflow(data["workflow"]);
        const parsedActions = data["actions"].map((a) => {
          return {
            ...a,
            payload: JSON.parse(a.payload),
          };
        });
        setSortedActions(parsedActions);
      } catch (err) {
        console.error(err);
      }
    };
    getWorkflow();
  }, [get, workflowId]);

  if (!actions || !workflow) {
    return null;
  }

  const toggleAction = (actionId) => {
    const action = actions.find((action) => action.id === actionId);

    if (selectedActions.length === 0) {
      setSelectedActions([action]);
      return;
    }

    if (selectedActions.findIndex((action) => action.id === actionId) !== -1) {
      const updatedArray = selectedActions.filter(
        (action) => action.id !== actionId,
      );
      setSelectedActions(updatedArray);
    } else {
      setSelectedActions([action, ...selectedActions]);
    }
  };

  const updateActionValue = (actionId, value) => {
    const action = actions.find((a) => a.id === actionId);
    action.payload = { message: value };
    setSortedActions([action, ...actions.filter((a) => a.id !== actionId)]);
  };

  const execute = async () => {
    setExecuting(true);
    try {
      await Promise.all(selectedActions.map((action) => postAction(action.id)));
      setExecutedActions(selectedActions);
      setSelectedActions([]);
    } catch (err) {
      setError(err.message);
    } finally {
      setExecuting(false);
    }
  };

  const postAction = async (actionId) => {
    const action = actions.find((action) => action.id === actionId);
    await post({
      url: `workflows/action/${actionId}/`,
      payload: {
        action_payload: action.payload,
      },
    });
  };

  return (
    <StyledLaunchPad>
      <Container>
        <Row style={{ marginBottom: "90px" }}>
          <Col lg={{ offset: 2, span: 8 }}>
            <SectionSubheader>{workflow.summary}</SectionSubheader>
            <Paragraph>{workflow.detail}</Paragraph>
            <WorkflowContextLink workflow={workflow} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2, span: 8 }}>
            <SectionSubheader>{plan.headline}</SectionSubheader>
            <Paragraph>{plan.detail}</Paragraph>
          </Col>
        </Row>
        {actions.map((a) => (
          <ActionStep
            action={a}
            key={a.id}
            toggleAction={toggleAction}
            updateActionValue={updateActionValue}
          />
        ))}
        <SelectedActions actions={selectedActions} />
        <ExecutedActions actions={executedActions} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Row>
          <Col lg={{ offset: 2, span: 4 }}>
            <ActionButton
              disabled={executing}
              onClick={execute}
              text={executing ? "Executing" : "Execute"}
            />
          </Col>
        </Row>
      </Container>
    </StyledLaunchPad>
  );
};

const WorkflowContextLink = ({ workflow }) => {
  if (workflow.origin_inbox === "gmail") {
    return (
      <a
        href={`https://mail.google.com/mail/u/0/${workflow.origin_message_id}`}
        target="_blank"
        rel="noreferrer"
      >
        View original message >>
      </a>
    );
  } else {
    console.error(`unspported origin: ${workflow.origin_inbox}`);
    return null;
  }
};

const ActionStep = ({ action, toggleAction, updateActionValue }) => {
  switch (action.action_type) {
    case "send_message_from_user":
      return (
        <SendMessageAction
          action={action}
          toggleAction={toggleAction}
          updateActionValue={updateActionValue}
        />
      );
    case "send_message_from_bot":
      return (
        <BotAction
          action={action}
          toggleAction={toggleAction}
          updateActionValue={updateActionValue}
        />
      );
    default:
      console.error(`unsupported action type: ${action.action_type}`);
  }
};

const SendMessageAction = ({ action, toggleAction, updateActionValue }) => {
  const [messageText, setMessageText] = useState("");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setMessageText(action.detail);
  }, [action.detail, setMessageText]);

  const onClick = () => {
    setSelected(!selected);
    toggleAction(action.id);
  };

  const onTextChange = (value) => {
    setMessageText(value);
    updateActionValue(action.id, value);
  };

  return (
    <Row>
      <Col lg={{ offset: 2, span: 8 }}>
        <StyledActionStep>
          <SectionSubheader>{action.summary}</SectionSubheader>
          <textarea
            onChange={(e) => onTextChange(e.target.value)}
            value={messageText}
          />
          <ActionButton
            className={selected ? "selected" : null}
            text={selected ? "Selected" : "Select"}
            onClick={onClick}
          />
        </StyledActionStep>
      </Col>
    </Row>
  );
};

const BotAction = ({ action, toggleAction }) => {
  return (
    <Row>
      <Col lg={{ offset: 2, span: 8 }}>
        <StyledActionStep>
          <SectionSubheader>{action.summary}</SectionSubheader>
          <Paragraph>{action.detail}</Paragraph>
          <ActionButton text="Select" />
        </StyledActionStep>
      </Col>
    </Row>
  );
};

const StyledLaunchPad = styled.div`
  padding: ${quadSpace} 0;

  .row {
    margin-bottom: ${tripleSpace};
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-bottom: ${doubleSpace};
  }

  p {
    margin-bottom: ${singleSpace};
  }
`;

const StyledActionStep = styled.div`
  border: 1px solid ${lightGray};
  padding: ${doubleSpace};
  border-radius: 5px;

  button {
    padding: 10px;
    max-width: 200px;
    margin-top: ${singleSpace};

    &.selected {
      background-color: ${lightBlue};
    }
  }

  textarea {
    width: 100%;
    padding: ${singleSpace};
    min-height: 200px;

    ${md} {
      min-height: 120px;
    }
  }
`;
