import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

import {
  gray,
  PrimaryButton,
  doubleSpace,
  ErrorMessage,
  PageHeader,
  quadSpace,
  Paragraph,
  SectionHeader,
  tripleSpace,
} from "components/sharedComponents";
import { HttpClientContext } from "components/HttpClient";
import { AuthContext } from "components/AuthContext";

import { Clean } from "./components/Clean";
import { Results } from "./components/Results";

export const CleanInbox = () => {
  const { login, user } = useContext(AuthContext);
  const { authorizedInboxes } = user;
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [results, setResults] = useState(null);
  const { post } = useContext(HttpClientContext);

  if (!authorizedInboxes.includes("google")) {
    navigate("/inboxes");
  }

  const onClick = async () => {
    try {
      setLoading(true);
      const res = await post({
        url: `jobs/create/`,
        payload: { type: "clean" },
      });
      console.log(res.data.results);
      setResults(res.data.results);
      setLoading(false);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          {results ? (
            <Results results={results} />
          ) : (
            <Clean onClick={onClick} error={error} loading={loading} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
