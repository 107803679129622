import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  doubleSpace,
  Paragraph,
  singleSpace,
  quadSpace,
} from "components/sharedComponents";
import { ThreePartHeader } from "components/ThreePartHeader";

const t1 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/testimonial-1.png`;
const t2 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/testimonial-2.png`;
const t3 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/testimonial-3.png`;
const t4 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/testimonial-4.png`;

export const Testimonials = () => {
  return (
    <StyledTestimonials>
      <Container fluid>
        <ThreePartHeader
          title="What our customers are saying"
          subtitle="The People Have Spoken"
        />
        <Row>
          <Testimonial
            image={t1}
            name="Elisa T"
            quote="I literally sleep better at night knowing that my parents have someone looking out for them."
            theme="dark"
          />
          <Testimonial
            image={t2}
            name="Ross W"
            quote="I used to dread checking my email. With Sanctum filtering out the bad stuff, I actually enjoy being online again!"
            theme="light"
          />
        </Row>
        <StyledSecondRow>
          <Row>
            <Testimonial
              image={t3}
              name="Felipe C"
              quote="Before I got Sanctum, I felt like protecting my family from online threats was hopeless. Now it's easy."
              theme="light"
            />
            <Testimonial
              image={t4}
              name="Megan A"
              quote="I can't believe I didn't have this before. It just makes sense as a way to protect my family's assets."
              theme="dark"
            />
          </Row>
        </StyledSecondRow>
      </Container>
    </StyledTestimonials>
  );
};

const Testimonial = ({ image, name, theme, quote }) => {
  return (
    <Col
      xs={{ span: 12 }}
      md={{ span: 6 }}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <StyledTestimonial className={theme}>
        <div className="content-container">
          <Paragraph className="large">{quote}</Paragraph>
          <Paragraph className="small">{name}</Paragraph>
          <StyledImageContainer>
            <img src={image} alt="Customer" />
          </StyledImageContainer>
        </div>
      </StyledTestimonial>
    </Col>
  );
};

const StyledTestimonials = styled.section`
  margin-bottom: ${quadSpace};
  .divider {
    margin-bottom: 90px;
  }
`;

const StyledTestimonial = styled.div`
  padding: ${quadSpace} ${doubleSpace} ${singleSpace};
  &.dark {
    background-color: ${blue};
    color: white;
  }

  .content-container {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const StyledSecondRow = styled.div`
  @media (max-width: 768px) {
    .dark {
      background-color: inherit;
      color: inherit;
    }
    .light {
      background-color: ${blue};
      color: white;
    }
  }
`;

const StyledImageContainer = styled.div`
  text-align: right;
  top: -20px;
  position: relative;
  padding-right: 5%;
`;
