import React from "react";
import styled from "styled-components";

import {
  doubleSpace,
  lightBlue,
  PageHeader,
  Paragraph,
  PrimaryButton,
} from "components/sharedComponents";

export const HeroCapture = () => {
  return (
    <StyledHeroCapture>
      <PageHeader>Keep your family safe online</PageHeader>
      <Paragraph>
        Sanctum provides tools and training to keep you and your family safe
        from online threats
      </Paragraph>
      <div>
        <PrimaryButton text="Get Started Free" link="/goku" type="white" />
      </div>
    </StyledHeroCapture>
  );
};

const StyledHeroCapture = styled.div`
  color: white;

  h1 {
    margin-bottom: ${doubleSpace};
  }

  span {
    color: ${lightBlue};
  }

  p {
    margin-bottom: ${doubleSpace};
  }
`;
