import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  CardHeader,
  doubleSpace,
  Paragraph,
  PageHeader,
  quadSpace,
  SectionHeader,
  singleSpace,
  tripleSpace,
} from "components/sharedComponents";

const bjorn = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/bjorn.jpg`;
const mark = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/mark.jpg`;
const mike = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/mike.jpg`;
const sean = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/team/sean.jpg`;
const linkedin = `${process.env.REACT_APP_ASSETS_URL}/img/linkedin.png`;

const people = [
  {
    bio: `
    Bjorn began his career in international development, working in Afghanistan and Haiti to help the world's poorest people. While in Afghanistan he noticed that
    the people who made a real difference were the people with hard technical skills - agronomists, surgeons, customs inspectors. So he quit his job and became an
    engineer. He launched his first company, Doorstep
    Studios, in 2013, building software for Silicon Valley startups, nonprofits, and Fortune 500 companies. In 2016 he joined MissionU as the founding engineer, leading
    their technical team until their acquisition. From 2018 to 2021 he lead the consumer engineering team at TaskRabbit, scaling the team 3X. In 2021 he left
    TaskRabbit to lead technology for TheKey, the country's largest private-pay home care provider. Bjorn earned his BA
    from Yale University.
    `,
    image: bjorn,
    link: "https://www.linkedin.com/in/bjorncooley/",
    name: "Bjorn Cooley",
    title: "CEO",
  },
  {
    bio: `
      In 2005 Sean founded Flurry, which he grew into the largest analytics and ads platform for mobile apps, commanding 98% of the mobile application market when it was acquired by Yahoo! In 2014 for over $300M. 

      In 2015, he founded Outlier.ai with Dr. Kim, which created the automated analysis category by using AI to help leading brands (Nike, Capital One, et al.) find important business insights hiding in their data. Outlier's backers include First Round Capital, Homebrew, and Emergence Capital. 

      He has been an investor since 2013 through LucidFog, a personal investment fund that has deployed $1M into over 75 companies (including first checks into unicorns LaunchDarkly & EasyPost) and ten venture funds. 

      Sean did his graduate research in Artificial Intelligence at Cornell University and studied robotics as an undergraduate at Dartmouth College.
      `,
    image: sean,
    link: "https://www.linkedin.com/in/sbyrnes/",
    name: "Sean Byrnes",
    title: "Customer Development",
  },
  {
    bio: `
    Mark began his career founding and building companies in 1994 with Small World Sports, where he invented online fantasy sports, before selling it to Paul Allen’s Vulcan Ventures. Mark also co-founded and spun out Small World Software, which he sold to iXL before its 1998 IPO.

    Mark co-founded Sequoia-backed Digital Chocolate, a mobile game pioneer, with Trip Hawkins and was recruited by Sequoia to loopt (sold to GreenDot), as the “adult supervision” for 19yo Sam Altman. Khosla Ventures recruited Mark as the CEO of mobile VOIP pioneer iSkoot, which he sold to Qualcomm.

    Mark also spent four years building Guardant Health as its CUEO, from 50 employees until its 2019 IPO, and was the CBO of Immunai, a world-leading AI-driven biotech unicorn at the intersection of ML and single-cell genomics.  

    Mark earned his BA from Harvard, where he concentrated in Computer Science, and lives in Stanford, CA, with his wife and two daughters.
    `,
    image: mark,
    link: "https://www.linkedin.com/in/markjacobstein/",
    name: "Mark Jacobstein",
    title: "Business Development",
  },
  {
    bio: `
    Mike has been at the cutting edge of data science for over twenty years. He has worked across various industries, ranging from cryptography to structural biology, from education to telecommunications, and from social networking to enterprise analytics, inventing and developing novel solutions in all of these areas.

    Mike built one of the world’s first knowledge routing systems at Aardvark, where he was employee 10. After Google acquired Aardvark, Mike led data science teams that reimagined personalization across all of Google’s products. In 2015, he co-founded Outlier.ai with Sean and invented the technology and algorithms behind the automated business analysis category. Mike has 9 patents, each of which advanced the state of the art.

    Mike has an undergraduate degree from Stanford University in Computer Science and his Ph.D. from UCSF in Bioinformatics, writing his dissertation on the modeling of macromolecular assemblies.
    `,
    image: mike,
    link: "https://www.linkedin.com/in/mikefkim/",
    name: "Mike Kim",
    title: "Engineering & AI",
  },
];

const StyledAbout = styled.section`
  border-top: 1px solid ${blue};
  padding: 30px 15px;

  @media only screen and (min-width: 768px) {
    padding: 60px 15px;
  }

  h1,
  h2 {
    margin-bottom: ${doubleSpace};
  }

  p {
    margin-bottom: ${singleSpace};
    line-height: 32px;
  }
`;

export const OurStory = () => {
  return (
    <StyledAbout id="about">
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <PageHeader>About Sanctum</PageHeader>
            <SectionDivider />
          </Col>
        </Row>
        <Row style={{ marginBottom: "60px" }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <SectionHeader>Our Story</SectionHeader>
            <Paragraph>
              On a rainy January afternoon I got an unexpected call from my
              sister. My mom had just lost $10,000 to fraud. The scammers had
              figured out who mom's healthcare provider was, crafted an email
              that looked legitimate, and convinced my mom that she had an
              outstanding balance that she needed to pay. So, my mom clicked the
              link and started putting in her information. She began getting
              more emails, which lead to phone calls, which led to her giving up
              her financial account information.
            </Paragraph>
            <Paragraph>
              We got lucky that my sister happened to check in on her soon after
              this started. My mom told her about the situation, and my sister
              immediately figured out it was a scam. We shut down her accounts,
              alerted her bank, and froze her credit. By then it was too late.
            </Paragraph>
            <Paragraph>
              That's when I realized we were vulnerable as a family. This first
              time it was $10,000 from my mom's bank account. I realized it
              could have been much worse - if my sister hadn't intervened, they
              might have taken everything my mom has. My mom is retired, and my
              dad passed away a few years ago. My mom can't afford to lose
              what's left.
            </Paragraph>
            <Paragraph>
              So I went out to find the best scam-protection service money could
              buy. What I found, was nothing. Almost every service I found was
              focused on identifying the scams after they had happened -
              identity theft insurance, transaction monitoring, virus scanners.
              The only proactive solutions seemed to be the default spam filters
              provided by companies like Google and Yahoo, which weren't keeping
              up with the scammers.
            </Paragraph>
            <Paragraph>
              After months of searching, I decided to solve the problem myself.
              I set out to build a business with the simple mission of keeping
              people safe online.
            </Paragraph>
            <Paragraph>-- Bjorn Cooley, CEO</Paragraph>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <SectionHeader>Our Team</SectionHeader>
          </Col>
        </Row>
        {people.map((p, i) => (
          <Person
            bio={p.bio}
            key={i}
            image={p.image}
            link={p.link}
            name={p.name}
            title={p.title}
          />
        ))}
      </Container>
    </StyledAbout>
  );
};

const Person = ({ bio, image, link, name, title }) => {
  return (
    <StyledPerson>
      <Row>
        <Col md={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 3 }}>
          <img className="circle" src={image} />
          <CardHeader>{name}</CardHeader>
          <Paragraph className="title">{title}</Paragraph>
        </Col>
        <Col md={{ span: 6 }} lg={{ span: 4 }}>
          <Paragraph>{bio}</Paragraph>
        </Col>
      </Row>
    </StyledPerson>
  );
};

const StyledPerson = styled.div`
  margin-bottom: ${quadSpace};

  h5 {
    margin-bottom: 0;
  }

  img.circle {
    object-fit: cover;
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }

  img.linkedin {
    max-width: 20px;
    margin-left: 10px;
  }
`;

const SectionDivider = styled.div`
  height: 4px;
  background-color: ${blue};
  display: flex;
  width: 165px;
  margin-bottom: ${tripleSpace};
`;
