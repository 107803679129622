import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import {
  blue,
  doubleSpace,
  quadSpace,
  md,
  PageHeader,
  Paragraph,
  PrimaryButton,
  singleSpace,
} from "components/sharedComponents";
const heroImage = `${process.env.REACT_APP_ASSETS_URL}/img/product-page/customer-1.png`;

export const Hero = ({ headerText, subheaderText }) => {
  return (
    <StyledHero>
      <Container>
        <Row>
          <Col md={{ span: 6 }}>
            <StyledHeroCapture>
              <PageHeader>{headerText}</PageHeader>
              <Paragraph>{subheaderText}</Paragraph>
              <div className="d-flex">
                <PrimaryButton link="/plans" text="Let's go" />
              </div>
            </StyledHeroCapture>
          </Col>
          <Col md={{ span: 6 }} style={{ textAlign: "center" }}>
            <img src={heroImage} alt="Customer" />
          </Col>
        </Row>
      </Container>
    </StyledHero>
  );
};

const StyledHero = styled.section`
  margin: 60px 0 120px;

  img {
    max-width: 500px;
    border-radius: 10px;
  }
`;

const StyledHeroCapture = styled.div`
  h1,
  p {
    max-width: 500px;
  }
  h1 {
    margin-bottom: ${doubleSpace};
  }

  p {
    margin-bottom: ${doubleSpace};
  }

  ${md} {
    div:nth-child(2) {
      margin-left: ${singleSpace};
    }
  }
`;
