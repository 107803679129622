import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {
  ActionButton,
  Paragraph,
  SectionSubheader,
} from "components/sharedComponents";

export const ExecutedActions = ({ actions }) => {
  if (actions.length === 0) {
    return null;
  }
  return (
    <Row style={{ marginTop: "90px" }}>
      <Col lg={{ offset: 2, span: 8 }}>
        <SectionSubheader>
          Done! I will notify you if there are any significant updates.
        </SectionSubheader>
        <SectionSubheader>Tasks completed:</SectionSubheader>
        <ul>
          {actions.map((a) => (
            <li>
              <ExecutedAction action={a} key={a.id} />
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const ExecutedAction = ({ action }) => {
  return <Paragraph>{action.summary}</Paragraph>;
};
