import React, { useState } from "react";
import styled from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";

import {
  blue,
  darkGray,
  doubleSpace,
  ErrorMessage,
  halfSpace,
  singleSpace,
  Paragraph,
  tripleSpace,
} from "components/sharedComponents";

export const EmailSubjectResult = ({
  section,
  email,
  deniedSenders,
  denySender,
  openMessage,
  moveMessage,
  readOnly,
}) => {
  const [denied, setDenied] = useState(false);
  const [error, setError] = useState(null);

  const move = async () => {
    try {
      await moveMessage(email.id);
    } catch (err) {
      setError(err.message);
    }
  };

  const link = `https://mail.google.com/mail/u/0/#inbox/${email.id}`;

  const sender = email.from.map(({ name, email }) => name || email).join(", ");
  const deniedSender = deniedSenders.find(
    (sender) => sender === email.from[0].email,
  );

  const getMoveOptions = () => {
    if (readOnly) return null;

    if (email.movedLabel) return <div>{email.movedLabel}</div>;

    const label = section === "inbox" ? "Remove from Inbox" : "Move to Inbox";

    return <StyledLink onClick={move}>{label}</StyledLink>;
  };

  const handleDeny = () => {
    setDenied(!denied);
    denySender(email.id);
  };

  return (
    <StyledEmailResult
      id={email.id}
      style={email.moved && section !== "inbox" ? { opacity: 0.4 } : null}
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SpaceBetweenContainer>
        <Paragraph className="bold">{sender}</Paragraph>
        <Paragraph className="small">{formatDate(email.send_date)}</Paragraph>
      </SpaceBetweenContainer>
      <Paragraph>{email.summary}</Paragraph>
      <Footer>
        <BrowserView>
          <StyledLink href={link} target="_blank">
            Go to Message
          </StyledLink>
        </BrowserView>
        <MobileView>
          <StyledLink onClick={() => openMessage(email.id)}>
            View Message
          </StyledLink>
        </MobileView>
        <ButtonGroup>
          <StyledLink
            onClick={handleDeny}
            style={deniedSender ? { opacity: 0.4 } : null}
          >
            {deniedSender ? "Unblock Sender" : "Block This Sender"}
          </StyledLink>
          {getMoveOptions()}
        </ButtonGroup>
      </Footer>
    </StyledEmailResult>
  );
};

const formatDate = (epochTimeInMs) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(epochTimeInMs);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${doubleSpace};
`;

const StyledEmailResult = styled.div`
  margin: ${doubleSpace} 0 ${tripleSpace};
  border-bottom: 1px solid lightGray;
  padding: ${singleSpace} 0 ${doubleSpace};

  p {
    margin-bottom: ${singleSpace};
  }
`;

const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled.a`
  color: ${darkGray};
  border: 1px solid ${blue};
  border-radius: 10px;
  padding: ${halfSpace} ${singleSpace};
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${singleSpace};
  align-items: center;
`;
