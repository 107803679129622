import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { HeroCapture } from "./HeroCapture";
import { doubleSpace, Paragraph, Subheader } from "components/sharedComponents";
import { EmailCapture } from "components/EmailCapture";

const logo = `${process.env.REACT_APP_ASSETS_URL}/img/logo.png`;
const StyledHero = styled.section`
  display: flex;
  justify-content: center;
  padding: 12vh 0;
  text-align: center;

  p {
    margin-bottom: ${doubleSpace};
  }
`;

export const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} style={{ marginBottom: "45px" }}>
            <Paragraph>
              Sanctum is currently in private beta. To request early access to
              Sanctum, join the waitlist below.
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <EmailCapture
              headline={"Join the Waitlist"}
              category="homepage-0.1"
            />
          </Col>
        </Row>
      </Container>
    </StyledHero>
  );
};
