import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import { AuthContext } from "components/AuthContext";
import { HttpClientContext } from "components/HttpClient";
import { MetaContext } from "components/MetaContext";

import {
  PrimaryButton,
  doubleSpace,
  ErrorMessage,
  quadSpace,
  Paragraph,
  Subheader,
  tripleSpace,
} from "components/sharedComponents";

export const CreateJob = () => {
  const { user } = useContext(AuthContext);
  const { get, post } = useContext(HttpClientContext);
  const { JobStatus, JobType } = useContext(MetaContext);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { authorizedInboxes } = user;

  if (!authorizedInboxes.includes("google")) {
    navigate("/inboxes");
  }

  const organize = async () => {
    try {
      setLoading(true);
      const res = await post({
        url: `jobs/create/`,
        payload: { type: JobType.ORGANIZE },
      });
      const jobId = res.data.job_id;
      pollForJob({ jobId });
    } catch (err) {
      setError(err.message);
    }
  };

  const pollForJob = async ({ jobId }) => {
    const intervalId = setInterval(async () => {
      let res;
      try {
        res = await get({
          url: "jobs/status/",
          params: { id: jobId },
        });
        const status = res.data?.job?.status;
        console.log(res.data?.job);
        if (status === JobStatus.PROCESSED) {
          navigate(`/organize-inbox/${jobId}`);
          clearInterval(intervalId);
        }
      } catch (err) {
        clearInterval(intervalId);
        setError(err.message);
        console.error(err);
      }
    }, 5000);
  };

  return (
    <StyledOrganize>
      <Container>
        <Row>
          <Col md={{ offset: 3, span: 6 }}>
            <StyledHeader>
              <Subheader>Your agent is ready.</Subheader>
              <Paragraph>
                The agent's first step is to generate a proposal for how it will
                clean your inbox. You can adjust the proposal before applying it
                to your inbox. Click the button below to get started.
              </Paragraph>
            </StyledHeader>

            <div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <StyledButton>
                <PrimaryButton
                  disabled={loading}
                  onClick={organize}
                  text={loading ? "Working..." : "Organize my inbox"}
                />
              </StyledButton>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledOrganize>
  );
};

const StyledOrganize = styled.div`
  flex: 1;
  padding-top: ${quadSpace};
`;

const StyledHeader = styled.div`
  padding-top: ${quadSpace};

  h3,
  p {
    margin-bottom: ${doubleSpace};
  }
  margin-bottom: ${doubleSpace};
`;

const StyledButton = styled.div`
  margin-top: ${tripleSpace};
  margin-bottom: ${tripleSpace};
  button {
    width: 280px;
  }
`;
