import React, { useState, useRef } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { Spinner } from "react-bootstrap";

import { GenericButton } from "components/sharedComponents";

import "./EmailBodyModal.css";

export const EmailBodyModal = ({ loading, emailBody, onClose }) => {
  const [htmlScale, setHtmlScale] = useState(1);
  const htmlRef = useRef(null);

  return (
    <Overlay>
      {loading ? (
        <Spinner animation="grow" />
      ) : (
        <ReactModal
          style={htmlModalStyle}
          isOpen={!!emailBody}
          onAfterOpen={() => {
            const emailWidth = htmlRef.current.scrollWidth;
            const windowWidth = window.innerWidth;
            const scale = windowWidth / emailWidth;
            if (scale < 1) setHtmlScale(windowWidth / (emailWidth + 15));
          }}
          onAfterClose={() => {
            setHtmlScale(1);
          }}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={true}
        >
          <ButtonContainer>
            <GenericButton onClick={onClose}>Close</GenericButton>
          </ButtonContainer>
          <div
            ref={htmlRef}
            style={{
              maxWidth: "600px",
              width: htmlScale === 1 ? "fit-content" : "100%",
              height: htmlScale === 1 ? "fit-content" : "100%",
              transform: `scale(${htmlScale}, ${htmlScale})`,
              transformOrigin: "top left",
              padding: "15px",
            }}
            dangerouslySetInnerHTML={{ __html: emailBody }}
          />
        </ReactModal>
      )}
    </Overlay>
  );
};

const htmlModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: 0,
    backgroundColor: "transparent",
    zIndex: 100,
  },
  content: {
    position: "absolute",
    top: "40px",
    left: "0",
    right: "0",
    bottom: "0",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "0",
    border: 0,
  },
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
`;
